import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AdminCurrentLC from '../Admin/AdminCurrentLC';
import { thousandSeparator } from '../../utils/math';
import { useEffect, useState } from 'react';
import TopUpPrizeModal from '../Season/TopUpPrizeModal';
import AnnouncementInfo from './AnnouncementInfo';
import DrawDateModal from './DrawDateModal';
import VideoLinkModal from '../Modal/VideoLinkModal';
import WinningNumbersModal from './WinningNumbersModal';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { resumePausePrize } from '../../actions/drawdown';
import WinningNumbers from './WinningNumbers';
import AnnouncementList from './AnnouncementList';
import { clearState } from '../../slices/drawdownSlice';
import FreezeDateModal from './FreezeDateModal';
import EditPrizeDistribution from './EditPrizeDistribution';
import { usePrizeCurrency } from '../../hooks/usePrizeCurrency';

const DrawDownDetail = () => {
  const { selectedDrawdownInfo, seasonID, selectedDrawdownID } = useSelector(
    (state) => state.drawdown
  );
  const [modalSection, setModalSection] = useState('');
  const [topUpPrizeNum, setTopUpPrizeNum] = useState(0);
  const currency = usePrizeCurrency('drawdown');
  const dispatch = useDispatch();

  const onPauseResumePrize = async (prize) => {
    const { prize_number, pause_distribution } = prize;
    const payload = {
      lottery_season_id: seasonID,
      drawdown_id: selectedDrawdownID,
      prize_number,
      pause_distribution: !pause_distribution,
    };
    const done = await dispatch(resumePausePrize(payload));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') enqueueSnackbar(message, { variant });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  return (
    <>
      {modalSection === 'topup_prize' ? (
        <TopUpPrizeModal
          closeModal={setModalSection}
          prizeNum={topUpPrizeNum}
        />
      ) : modalSection === 'announce_info' ? (
        <AnnouncementInfo closeModal={setModalSection} />
      ) : modalSection === 'add_draw_date' ? (
        <DrawDateModal closeModal={setModalSection} />
      ) : modalSection === 'video_link' ? (
        <VideoLinkModal closeModal={setModalSection} />
      ) : modalSection === 'winning_number' ? (
        <WinningNumbersModal closeModal={setModalSection} />
      ) : modalSection === 'add_freeze_date' ? (
        <FreezeDateModal closeModal={setModalSection} />
      ) : modalSection === 'edit_prize' ? (
        <EditPrizeDistribution closeModal={setModalSection} />
      ) : null}
      {selectedDrawdownInfo ? (
        <div className="flex flex-col lg:flex-row lg:justify-between w-full">
          <div className="flex flex-col w-full lg:w-[50%]">
            <h3 className="text-base text-green font-poppins-semibold">
              Draw ID: {selectedDrawdownInfo.drawdown.id}
            </h3>
            {selectedDrawdownInfo.updated_by ? (
              <p className="text-[10px] text-black-70">
                Last updated by: {selectedDrawdownInfo.updated_by}
              </p>
            ) : null}
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mt-[16px]">
              {!selectedDrawdownInfo.winning_numbers.number_list.length ? (
                <p className="text-sm opacity-50">Input the Win Numbers</p>
              ) : (
                <WinningNumbers
                  numbers={selectedDrawdownInfo.winning_numbers.number_list}
                  extra={selectedDrawdownInfo.winning_numbers.extra_number}
                />
              )}
              {!selectedDrawdownInfo.winning_numbers.number_list.length &&
              selectedDrawdownInfo.purchase_freeze_time &&
              selectedDrawdownInfo.draw_date &&
              selectedDrawdownInfo.status === 'Active' ? (
                <button
                  onClick={() => setModalSection('winning_number')}
                  className="w-fit mt-[12px] lg:mt-0 bg-white border border-black-100 rounded-[20px] px-[16px] py-[6px] font-poppins-semibold"
                >
                  Add Win Numbers
                </button>
              ) : null}
            </div>
            <div className="flex flex-row justify-between items-center mt-[24px]">
              {!selectedDrawdownInfo.purchase_freeze_time ? (
                <>
                  <p className="text-sm font-poppins-semibold">
                    Freeze Date: -
                  </p>
                  <button
                    onClick={() => setModalSection('add_freeze_date')}
                    className="bg-white border border-black-100 rounded-[20px] px-[16px] py-[6px] font-poppins-semibold"
                  >
                    Freeze Date
                  </button>
                </>
              ) : (
                <p className="text-sm">
                  <span className="font-poppins-semibold"> Freeze Date:</span>{' '}
                  {moment(selectedDrawdownInfo.purchase_freeze_time).format(
                    'DD-MM-YYYY, HH:mm:ss'
                  )}
                </p>
              )}
            </div>
            <div className="flex flex-row justify-between items-center mt-[10px] mb-[24px] pb-[24px] border-b border-b-grey-20">
              {!selectedDrawdownInfo.draw_date ? (
                <>
                  <p className="text-sm font-poppins-semibold">Draw Date: -</p>
                  {selectedDrawdownInfo.purchase_freeze_time ? (
                    <button
                      onClick={() => setModalSection('add_draw_date')}
                      className="bg-white border border-black-100 rounded-[20px] px-[16px] py-[6px] font-poppins-semibold"
                    >
                      Draw Date
                    </button>
                  ) : null}
                </>
              ) : (
                <p className="text-sm">
                  <span className="font-poppins-semibold"> Draw Date:</span>{' '}
                  {moment(selectedDrawdownInfo.draw_date).format(
                    'DD-MM-YYYY, HH:mm:ss'
                  )}
                </p>
              )}
            </div>
            <div className="flex flex-row justify-between">
              <AdminCurrentLC
                type="winning"
                textAlign="left"
                amount={selectedDrawdownInfo.winning_pot_budget}
              />
              {moment().isBefore(selectedDrawdownInfo.purchase_freeze_time) &&
              selectedDrawdownInfo.status !== 'Finished' ? (
                <button
                  onClick={() => setModalSection('edit_prize')}
                  type="button"
                  className="w-[40%] sm:w-fit h-fit py-[4px] px-[16px] rounded-[20px] font-poppins-semibold border border-black-100"
                >
                  Edit Prize Distribution
                </button>
              ) : null}
            </div>
            {selectedDrawdownInfo.prize_list.map((prize) => (
              <div
                className="flex flex-col text-sm border-b border-b-grey-20 py-[20px]"
                key={prize.prize_number}
              >
                <div className="flex flex-row items-center mb-[11px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[30%]">
                    {prize.prize_number === 1
                      ? 'First '
                      : prize.prize_number === 2
                      ? 'Second '
                      : prize.prize_number === 3
                      ? 'Third '
                      : null}
                    Prize
                  </label>
                  <button
                    className="flex items-center"
                    type="button"
                    onClick={() => onPauseResumePrize(prize)}
                  >
                    <ion-icon
                      name={
                        prize.pause_distribution
                          ? 'pause-circle-outline'
                          : 'play-circle-outline'
                      }
                      style={{
                        width: '24px',
                        height: '24px',
                        color: '#27AE60',
                      }}
                    ></ion-icon>
                    <p className="opacity-50 ml-[5px]">
                      {prize.pause_distribution ? 'Pause' : 'Auto'} Adding
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTopUpPrizeNum(prize.prize_number);
                      setModalSection('topup_prize');
                    }}
                    className="hidden lg:block bg-white ml-auto w-fit border border-black-100 rounded-[20px] px-[16px] py-[6px] font-poppins-semibold"
                  >
                    Top Up Prize
                  </button>
                </div>
                <div className="flex flex-row mb-[11px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[30%]">
                    Prize Distribution
                  </label>
                  <p>{prize.prize_distribution}%</p>
                </div>
                <div className="flex flex-row mb-[11px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[30%]">
                    Winning Pot
                  </label>
                  <div className="flex flex-col lg:flex-row">
                    <p>
                      {currency} {thousandSeparator(prize.initial_pot)}
                    </p>
                    <p className="opacity-50 mt-[4px] lg:mt-0 lg:ml-[5px]">
                      (Previous Amount)
                    </p>
                  </div>
                </div>
                <div className="flex flex-row mb-[11px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[30%]">
                    Current Amount
                  </label>
                  <p>
                    {currency} {thousandSeparator(prize.current_pot)}
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setTopUpPrizeNum(prize.prize_number);
                    setModalSection('topup_prize');
                  }}
                  className="lg:hidden bg-white ml-auto w-fit border border-black-100 rounded-[20px] px-[16px] py-[6px] font-poppins-semibold"
                >
                  Top Up Prize
                </button>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full lg:w-[42%] mt-[24px] lg:mt-0">
            <div className="flex flex-row items-center">
              <h3 className="font-poppins-semibold text-base text-green">
                Announcement
              </h3>
              <button
                type="button"
                className="flex"
                onClick={() => setModalSection('announce_info')}
              >
                <ion-icon
                  name="information-circle-outline"
                  style={{ width: '16px', height: '16px', marginLeft: '4px' }}
                ></ion-icon>
              </button>
            </div>
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-sm font-poppins-semibold">Video Link</h3>
              <button
                type="button"
                onClick={() => setModalSection('video_link')}
                className="bg-white border border-black-100 rounded-[20px] px-[16px] py-[6px] font-poppins-semibold text-sm"
              >
                Add Link
              </button>
            </div>
            <AnnouncementList />
          </div>
        </div>
      ) : (
        <p>Add Draw session to start</p>
      )}
    </>
  );
};

export default DrawDownDetail;
