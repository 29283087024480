import Modal from '../Modal/Modal';
import AdminCurrentLC from '../Admin/AdminCurrentLC';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { topUpPrize } from '../../utils/validationForm';
import Input from '../Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { drawdownTopUpPrize } from '../../actions/drawdown';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { usePrizeCurrency } from '../../hooks/usePrizeCurrency';

const TopUpPrizeModal = ({ closeModal, prizeNum }) => {
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(topUpPrize),
  });
  const { seasonID, selectedDrawdownID } = useSelector(
    (state) => state.drawdown
  );
  const dispatch = useDispatch();
  const currency = usePrizeCurrency('drawdown');

  useEffect(() => {
    const data = {
      lottery_season_id: seasonID ? seasonID : '',
      drawdown_id: selectedDrawdownID ? selectedDrawdownID : '',
      prize_number: prizeNum,
    };
    reset(data);
  }, [seasonID, selectedDrawdownID, prizeNum, reset]);

  const onSubmit = async (data) => {
    const done = await dispatch(drawdownTopUpPrize(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };

  return (
    <Modal title="Top Up Prize" closeModal={closeModal}>
      <AdminCurrentLC type="winning" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="number"
          register={register}
          title={`Total Prize (${currency} Pot)`}
          name="topup_amount"
          placeholder={currency}
          errors={formState.errors}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => reset()}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default TopUpPrizeModal;
