import { useRef, useState } from 'react';
import ActionButton from '../Button/ActionButton';
import SecondaryButton from '../Button/SecondaryButton';
import Modal from '../Modal/Modal';
import Tutorial1 from '../../assets/imgs/tutorial/tutorial-1.png';
import Tutorial21 from '../../assets/imgs/tutorial/tutorial-2-1.png';
import Tutorial22 from '../../assets/imgs/tutorial/tutorial-2-2.png';
import Tutorial31 from '../../assets/imgs/tutorial/tutorial-3-1.png';
import Tutorial32 from '../../assets/imgs/tutorial/tutorial-3-2.png';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useDispatch } from 'react-redux';
import { checkTransactionId } from '../../actions/wallet';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import LCAmount from './LCAmount';

const ExchangeWallet = ({ closeModal }) => {
  const idRef = useRef();
  const [openTutorial, setOpenTutorial] = useState([]);
  const dispatch = useDispatch();
  const { copyText } = useCopyToClipboard();

  const tutorials = [
    {
      title:
        '<p>1. Click <span class="font-poppins-semibold"> Open Latoken</span>, login and buy CKU.</p>',
      content: [
        { desc: 'Input CKU amounts you want to exchange.' },
        { desc: 'Click or Input current price.' },
        { desc: 'Click Buy CKU (make sure you have sufficient funds).' },
      ],
      images: [Tutorial1],
    },
    {
      title:
        '<p>2. Go to the transaction page and transfer the CKU to LC88 wallet.</p>',
      content: [
        { desc: 'Go to Wallet, and You’ll see your CKU is added.' },
        { desc: 'Transfer Your CKU to the LuckyCat88 Wallet Address.' },
      ],
      showSupportBtn: true,
      images: [Tutorial21, Tutorial22],
    },
    {
      title:
        '<p>3. After transfer CKU is succeed, paste transaction ID here to check.</p>',
      content: [
        {
          desc: 'Find Transaction ID in your Transaction history.',
          more: [
            'Copy the transaction ID',
            'Paste the transaction ID here to complete the transfer.',
          ],
        },
        {
          desc: "Once you've pasted the transaction ID, and the transfer is verified, you'll see your CKU added.",
        },
      ],
      images: [Tutorial31, Tutorial32],
    },
  ];

  const checkId = async () => {
    const transactionId = idRef.current.value;
    if (transactionId) {
      const done = await dispatch(
        checkTransactionId({ external_transaction_id: transactionId })
      );
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        idRef.current.value = '';
        enqueueSnackbar(message, { variant });
      }
    }
  };

  return (
    <Modal closeModal={closeModal} title="Get CKU">
      <div className="flex flex-col gap-[16px]">
        <LCAmount />
        <div className="flex flex-col p-[8px] bg-light-yellow border border-black-100 gap-[10px]">
          <h3 className="text-sm font-poppins-semibold">
            How to get CKU to play:
          </h3>
          {tutorials.map((tutor, idx) => (
            <div
              className="flex flex-col border-b py-[10px] leading-[20px]"
              key={`tutor-${idx}`}
            >
              <div
                className="flex flex-row justify-between cursor-pointer"
                onClick={() =>
                  openTutorial.includes(idx + 1)
                    ? setOpenTutorial((prevState) =>
                        prevState.filter((number) => number !== idx + 1)
                      )
                    : setOpenTutorial((prevState) => [...prevState, idx + 1])
                }
              >
                <div
                  className="text-sm w-[80%]"
                  dangerouslySetInnerHTML={{ __html: tutor.title }}
                ></div>
                <ion-icon
                  name={
                    openTutorial.includes(idx + 1)
                      ? 'chevron-up-outline'
                      : 'chevron-down-outline'
                  }
                  style={{ width: '16px', height: '16px' }}
                ></ion-icon>
              </div>
              <div
                className={`${
                  openTutorial.includes(idx + 1)
                    ? 'max-h-[100vh] h-full opacity-100'
                    : 'max-h-0 h-0 opacity-0'
                } flex flex-col transition-all duration-500`}
              >
                <ul className={`list-[lower-alpha] ml-[20px] text-sm h-full`}>
                  {tutor.content.map((cont, contId) => (
                    <li key={`${idx}-cont-${contId}`} className="h-full">
                      {cont.desc}
                      {cont.more ? (
                        <ul className="list-[lower-roman] ml-[20px] h-full">
                          {cont.more.map((more, moreId) => (
                            <li
                              key={`${idx}-cont-${contId}-${moreId}`}
                              className="h-full"
                            >
                              {more}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}
                </ul>
                {tutor.showSupportBtn ? (
                  <div
                    className="flex flex-row justify-between items-center p-[8px] pl-[20px] cursor-pointer"
                    onClick={() =>
                      copyText(
                        process.env.REACT_APP_API === 'production'
                          ? 'deposit@luckycat88.com'
                          : 'support@luckycat88.com',
                        'Successfully copied email'
                      )
                    }
                  >
                    <p className="font-poppins-bold text-sm">
                      {process.env.REACT_APP_API === 'production'
                        ? 'deposit@luckycat88.com'
                        : 'support@luckycat88.com'}
                    </p>
                    <button type="button" className="flex">
                      <ion-icon
                        name="copy-outline"
                        style={{ width: '20px', height: '20px' }}
                      ></ion-icon>
                    </button>
                  </div>
                ) : null}
                <div className="flex flex-row justify-between h-full">
                  {tutor.images.map((img, imgId) => (
                    <div
                      className="w-[49%] h-full"
                      key={`tutor-${idx}-${imgId}`}
                    >
                      <img
                        src={img}
                        alt={`tutor-${idx}-${imgId}`}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row justify-between items-end pr-[4px]">
          <div className="flex flex-col w-[60%] sm:w-[65%]">
            <label className="text-sm">Transaction ID</label>
            <input
              placeholder="Latoken’s Transaction ID"
              ref={idRef}
              className="w-full text-sm p-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
            />
          </div>
          <SecondaryButton
            title="Check"
            classes="!w-fit !h-fit !py-[4px] !px-[12px]"
            onClick={checkId}
          />
        </div>
        <ActionButton
          title="Open Latoken"
          onClick={() =>
            window.open(
              'https://latoken.com/exchange/USDT',
              '_blank',
              'noopener,noreferrer'
            )
          }
        />
        <p className="text-sm opacity-50">
          <span className="font-poppins-semibold">Note:</span> Clicking this
          button will open a new tab on the third-party site
        </p>
      </div>
    </Modal>
  );
};

export default ExchangeWallet;
