import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchPayoutList = createAsyncThunk(
  'payout/fetchPayoutList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminCashPayoutList', {
        params,
      });
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePaymentProof = createAsyncThunk(
  'payout/updatePaymentProof',
  async (proofData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'adminUpdatePaymentProof',
        proofData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
