import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Common/Loading';
import { useEffect, useState } from 'react';
import SeasonNewModal from './SeasonNewModal';
import SeasonPitchModal from './SeasonPitchModal';
import { enqueueSnackbar } from 'notistack';
import { editSeasonBanner, editSeasonStatus } from '../../actions/season';
import getToastMessage from '../../utils/toastMessage';
import SeasonDeletePitch from './SeasonDeletePitch';
import ImageUpload from '../Common/ImageUpload';

const SeasonInfo = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPitchModal, setShowPitchModal] = useState(false);
  const [showDeletePitchModal, setShowDeletePitchModal] = useState(false);
  const [bannerImgLink, setBannerImgLink] = useState('');
  const { selectedSeasonInfo, isLoading } = useSelector(
    (state) => state.season
  );
  const dispatch = useDispatch();

  const handleStatusChange = async (ev) => {
    const seasonData = {
      lottery_season_id: selectedSeasonInfo.lottery_season.id,
      status: ev.target.value,
    };
    const done = await dispatch(editSeasonStatus(seasonData));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') enqueueSnackbar(message, { variant });
    }
  };

  useEffect(() => {
    if (bannerImgLink) {
      const seasonData = {
        lottery_season_id: selectedSeasonInfo.lottery_season.id,
        banner: {
          is_active: true,
          image: bannerImgLink,
        },
      };
      console.log(seasonData, '===seasondata');
      dispatch(editSeasonBanner(seasonData)).then((res) => {
        setBannerImgLink('');
        const { message, variant } = getToastMessage(res);
        if (variant === 'success') enqueueSnackbar(message, { variant });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerImgLink]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <ImageUpload
        type="banner"
        name="season-banner"
        setImage={setBannerImgLink}
      />
      {showDeletePitchModal ? (
        <SeasonDeletePitch
          closeModal={setShowDeletePitchModal}
          pitch={showDeletePitchModal}
        />
      ) : null}
      {showPitchModal ? (
        <SeasonPitchModal
          pitch={showPitchModal}
          closeModal={setShowPitchModal}
        />
      ) : null}
      {showEditModal ? (
        <SeasonNewModal isEdit={true} closeModal={setShowEditModal} />
      ) : null}
      {!isLoading && selectedSeasonInfo ? (
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="lg:w-[45%]">
            <div className="flex flex-row w-full items-center justify-between mb-[16px]">
              <h3 className="text-green font-poppins-semibold text-base">
                Season Information
              </h3>
              <button
                onClick={() => setShowEditModal(true)}
                className="bg-white rounded-[20px] font-poppins-semibold px-[16px] py-[4px] border border-black-100"
              >
                Edit Info
              </button>
            </div>
            <div className="w-full">
              <div className="flex flex-row w-full text-sm mb-[8px]">
                <label className="w-[50%] font-poppins-semibold">
                  Season Name
                </label>
                <p className="w-[50%]">
                  {selectedSeasonInfo.lottery_season.name}
                </p>
              </div>
              <div className="flex flex-row w-full text-sm mb-[8px]">
                <label className="w-[50%] font-poppins-semibold">
                  Start Date
                </label>
                <p className="w-[50%]">
                  {moment(selectedSeasonInfo.startDate).format(
                    'DD-MM-YYYY, HH:mm:ss'
                  )}
                </p>
              </div>
              <div className="flex flex-row w-full text-sm mb-[8px]">
                <label className="w-[50%] font-poppins-semibold">
                  Cash Payout Season
                </label>
                <p className="w-[50%] uppercase">
                  {selectedSeasonInfo.lottery_season.is_cash_payout
                    ? 'true'
                    : 'false'}
                </p>
              </div>
              <div className="flex flex-row w-full text-sm mb-[8px]">
                <label className="w-[50%] font-poppins-semibold">
                  Ticket Price
                </label>
                <p className="w-[50%]">{selectedSeasonInfo.ticket_price}</p>
              </div>
              <div className="flex flex-row w-full text-sm mb-[8px]">
                <label className="w-[50%] font-poppins-semibold">
                  Min Number
                </label>
                <p className="w-[50%]">{selectedSeasonInfo.min_number}</p>
              </div>
              <div className="flex flex-row w-full text-sm mb-[8px]">
                <label className="w-[50%] font-poppins-semibold">
                  Max Number
                </label>
                <p className="w-[50%]">{selectedSeasonInfo.max_number}</p>
              </div>
              <div className="flex flex-col w-full text-sm my-[8px] pt-[8px] border-t">
                <h3 className="text-green font-poppins-semibold text-base">
                  Banner
                </h3>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const imgInput = document.getElementById('image-upload');
                    imgInput.click();
                  }}
                >
                  <label className="w-[50%] font-poppins-semibold">
                    Upload Season Image
                  </label>
                  {selectedSeasonInfo.banner &&
                  selectedSeasonInfo.banner.image &&
                  selectedSeasonInfo.banner.is_active ? (
                    <div className="w-[296px] h-[153px] mb-[4px] rounded-lg">
                      <img
                        className="w-full h-full object-cover rounded-lg"
                        src={selectedSeasonInfo.banner.image}
                        alt="season banner"
                      />
                    </div>
                  ) : (
                    <div className="w-[296px] h-[153px] bg-grey-banner flex flex-col justify-center items-center rounded-lg mb-[4px]">
                      <ion-icon
                        name="images-outline"
                        style={{ width: '16px', height: '16px' }}
                      ></ion-icon>
                      <p className="text-sm mt-[8px]">Upload Banner</p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col mb-[16px] border-b pb-[8px]">
                  <div className="flex flex-row w-full items-center justify-between mt-[8px]">
                    <h3 className="font-poppins-semibold mb-[8px] text-base">
                      Sales Pitch/General Announcement
                    </h3>
                    <button
                      className="bg-white rounded-[20px] font-poppins-semibold px-[16px] py-[4px] border border-black-100"
                      onClick={() => setShowPitchModal(true)}
                    >
                      Add Pitch
                    </button>
                  </div>
                  {selectedSeasonInfo.sales_pitch &&
                  selectedSeasonInfo.sales_pitch.length ? (
                    <article className="flex flex-col w-full">
                      {selectedSeasonInfo.sales_pitch.map((pitch) => (
                        <div key={pitch._id}>
                          {!pitch.is_deleted ? (
                            <div className="flex flex-row justify-between w-full sm:w-[50%] whitespace-pre-wrap">
                              <p className="max-w-[80%]">{pitch.description}</p>
                              <div className="flex flex-row w-[15%] gap-[4px]">
                                <button
                                  className="flex"
                                  onClick={() => {
                                    let pitchData = { ...pitch };
                                    pitchData.sales_pitch_id = pitchData._id;
                                    pitchData._id = selectedSeasonInfo._id;
                                    setShowPitchModal(pitchData);
                                  }}
                                >
                                  <ion-icon
                                    name="create-outline"
                                    style={{ width: '18px', height: '18px' }}
                                  ></ion-icon>
                                </button>
                                <button
                                  className="flex"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      pitch.description
                                    );
                                    enqueueSnackbar(
                                      'Sucessfully copied sales pitch',
                                      {
                                        variant: 'success',
                                      }
                                    );
                                  }}
                                >
                                  <ion-icon
                                    name="copy-outline"
                                    style={{ width: '18px', height: '18px' }}
                                  ></ion-icon>
                                </button>
                                <button
                                  className="flex"
                                  onClick={() => {
                                    let pitchData = { ...pitch };
                                    pitchData.sales_pitch_id = pitchData._id;
                                    pitchData._id = selectedSeasonInfo._id;
                                    setShowDeletePitchModal(pitchData);
                                  }}
                                >
                                  <ion-icon
                                    name="trash-outline"
                                    style={{ width: '18px', height: '18px' }}
                                  ></ion-icon>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </article>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:w-[45%]">
            <h3 className="text-green font-poppins-semibold text-base">
              Activation Status
            </h3>
            <div className="relative flex flex-row w-full justify-between items-center text-sm mb-[8px]">
              <label className=" w-1/2 font-poppins-semibold">
                Activate season
              </label>
              <select
                defaultValue={selectedSeasonInfo.status}
                onChange={handleStatusChange}
                className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-none w-1/2"
              >
                <option value="Hidden">Hidden</option>
                <option value="Active">Active</option>
                <option value="Finished">Finished</option>
              </select>
              <span className="absolute right-[8px] top-[11px]">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </span>
            </div>
            <div className="flex flex-row w-full justify-between items-center text-sm mb-[8px]">
              <label className="w-1/2 font-poppins-semibold">Last update</label>
              <p className="w-1/2">
                {moment(selectedSeasonInfo.updatedAt).format(
                  'DD-MM-YYYY, HH:mm:ss'
                )}
              </p>
            </div>
            <div className="flex flex-row w-full justify-between items-center text-sm mb-[8px]">
              <label className="w-1/2 font-poppins-semibold">Updated by</label>
              <p className="w-1/2">
                {selectedSeasonInfo.updated_by
                  ? selectedSeasonInfo.updated_by
                  : '-'}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SeasonInfo;
