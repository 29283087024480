import { useDispatch, useSelector } from 'react-redux';
import Modal from './Modal';
import { thousandSeparator } from '../../utils/math';
import { useCallback, useEffect, useRef, useState } from 'react';
import ImageUpload from '../Common/ImageUpload';
import { updatePaymentProof } from '../../actions/payout';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../../utils/toastMessage';

const TransferProofModal = ({ closeModal }) => {
  const fileInputRef = useRef();
  const [proofImg, setProofImg] = useState({
    objectUrl: null,
    file: null,
  });
  const [startImgUpload, setStartImgUpload] = useState(false);
  const [uploadedProof, setUploadedProof] = useState(false);
  const { selectedPayout } = useSelector((state) => state.payout);
  const dispatch = useDispatch();

  const uploadPaymentProof = useCallback(async () => {
    if (typeof proofImg === 'string') {
      const payload = {
        transaction_id: selectedPayout.transaction_id,
        image: proofImg,
      };

      const done = await dispatch(updatePaymentProof(payload));
      if (done) {
        setStartImgUpload(false);
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') {
          enqueueSnackbar(message, { variant });
          closeModal();
        }
      }
    }
  }, [closeModal, dispatch, proofImg, selectedPayout.transaction_id]);

  useEffect(() => {
    if (typeof proofImg === 'string' && startImgUpload) {
      if (uploadedProof && uploadedProof === proofImg) closeModal(false);
      else uploadPaymentProof();
    }
  }, [proofImg, uploadPaymentProof, startImgUpload, uploadedProof, closeModal]);

  useEffect(() => {
    if (
      selectedPayout &&
      selectedPayout.proof_of_payment &&
      selectedPayout.proof_of_payment.image
    ) {
      setUploadedProof(selectedPayout.proof_of_payment.image);
      setProofImg(selectedPayout.proof_of_payment.image);
    }
  }, [selectedPayout]);

  return (
    <>
      <input
        type="file"
        className="hidden"
        onChange={(ev) => {
          setProofImg({
            objectUrl: URL.createObjectURL(ev.target.files[0]),
            file: ev.target.files[0],
          });
        }}
        accept="image/*"
        ref={fileInputRef}
      />
      <ImageUpload
        fileToUpload={startImgUpload && proofImg.file}
        setImage={setProofImg}
        type="proof_of_transfer"
      />
      <Modal title="Proof of Transfer" closeModal={closeModal}>
        {selectedPayout ? (
          <div className="flex flex-col mb-[10px] text-sm">
            <div className="flex flex-row items-center justify-between">
              Winner Email
              <span className="w-[67%] font-poppins-semibold">
                : {selectedPayout.player_email}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              Prize Type
              <span className="w-[67%] font-poppins-semibold">
                : {selectedPayout.transaction_type}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              Amount
              <span className="w-[67%] font-poppins-semibold">
                : Rp {thousandSeparator(selectedPayout.total_payment)}
              </span>
            </div>
          </div>
        ) : null}
        <div
          className="flex flex-col justify-center items-center gap-[8px] w-full h-[40vh] bg-gray-200 cursor-pointer"
          onClick={() => fileInputRef.current.click()}
        >
          {(typeof proofImg !== 'string' && proofImg.objectUrl) ||
          typeof proofImg === 'string' ? (
            <img
              src={typeof proofImg === 'string' ? proofImg : proofImg.objectUrl}
              alt="proof of transfer"
              className="w-full h-full object-contain"
            />
          ) : (
            <>
              <ion-icon
                name="image-outline"
                class="w-[50px] h-[50px] text-black-70"
              ></ion-icon>
              <p className="text-black-70">Choose Image</p>
            </>
          )}
        </div>
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => closeModal(false)}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            {uploadedProof ? 'Close' : 'Cancel'}
          </button>
          <button
            onClick={() => setStartImgUpload(true)}
            type="button"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            {uploadedProof ? 'Re-Upload' : 'Submit'}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TransferProofModal;
