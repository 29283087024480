import { useEffect, useState } from 'react';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketList } from '../actions/ticket';
import Loading from '../components/Common/Loading';
import { setPageData } from '../slices/ticketSlice';
import { useNavigate } from 'react-router-dom';
import StyledTable from '../components/Table/StyledTable';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTableCell from '../components/Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../components/Table/Pagination';
import WinningNumbers from '../components/Season/WinningNumbers';
import DateRangeModal from '../components/Modal/DateRangeModal';
import StatusFilterModal from '../components/Modal/StatusFilterModal';
import SeasonFilterModal from '../components/Modal/SeasonFilterModal';
import moment from 'moment';
import DataLimit from '../components/Table/DataLimit';

const TicketsPage = () => {
  const { isLoading, ticketList, pageData, totalPages } = useSelector(
    (state) => state.ticket
  );
  const [modalSection, setModalSection] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTicketList(pageData));
  }, [dispatch, pageData]);

  const onSearchTicket = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== pageData.search) {
      const search = ev.target.value;
      dispatch(setPageData({ search }));
    }
  };

  const goToTicketDetail = (id) => {
    navigate(`/ticket/${id}`);
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== pageData.page) {
      dispatch(setPageData({ page: ev.selected }));
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {modalSection === 'date_range' ? (
        <DateRangeModal
          selector="ticket"
          setter={setPageData}
          stateName="pageData"
          closeModal={setModalSection}
        />
      ) : modalSection === 'status_filter' ? (
        <StatusFilterModal closeModal={setModalSection} />
      ) : modalSection === 'season_filter' ? (
        <SeasonFilterModal closeModal={setModalSection} />
      ) : null}
      <LayoutAdmin pageSubtitle="Tickets" pageTitle="Tickets">
        <div className="flex flex-col mb-[24px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Ticket Number, Lottery Number or Status"
            onKeyDown={onSearchTicket}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <DataLimit
          initialLimit={pageData.dataLimit}
          setter={setPageData}
          classList="mb-[16px]"
        />
        <div className="mt-[12px]">
          <div className="flex flex-row gap-[8px] overflow-auto max-w-none w-full mb-[8px] pb-[8px]">
            <button
              type="button"
              onClick={() => setModalSection('status_filter')}
              className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
            >
              Status Filter
            </button>
            <button
              type="button"
              onClick={() => setModalSection('date_range')}
              className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
            >
              Date Filter
            </button>
            <button
              type="button"
              onClick={() => setModalSection('season_filter')}
              className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
            >
              Season Filter
            </button>
          </div>
          {ticketList && ticketList.length && !isLoading ? (
            <div className="flex flex-col">
              <StyledTableContainer>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Ticket Number</StyledTableCell>
                      <StyledTableCell>Ticket Status</StyledTableCell>
                      <StyledTableCell>Lottery Number</StyledTableCell>
                      <StyledTableCell>Player Email</StyledTableCell>
                      <StyledTableCell>Purchase Date</StyledTableCell>
                      <StyledTableCell>Season</StyledTableCell>
                      <StyledTableCell>Draw Down</StyledTableCell>
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketList.map((ticket) => (
                      <TableRow key={ticket._id}>
                        <StyledTableCell>{ticket.ticket_id}</StyledTableCell>
                        <StyledTableCell>
                          {ticket.ticket_status}
                        </StyledTableCell>
                        <StyledTableCell>
                          <WinningNumbers
                            numbers={ticket.ticket_number}
                            showCopy={false}
                            noPadding={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{ticket.player_email}</StyledTableCell>
                        <StyledTableCell>
                          {moment(ticket.createdAt).format(
                            'DD-MM-YYYY, HH:mm:ss'
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {ticket.lottery_season.name}
                        </StyledTableCell>
                        <StyledTableCell>{ticket.drawdown.id}</StyledTableCell>
                        <StyledTableCell sx={{ textAlign: 'center' }}>
                          <button
                            onClick={() => goToTicketDetail(ticket.ticket_id)}
                            className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                          >
                            Detail
                          </button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={totalPages}
                pageData={pageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <h3 className="text-center mt-[5rem] text-base">
              No tickets data found
            </h3>
          )}
        </div>
      </LayoutAdmin>
    </>
  );
};

export default TicketsPage;
