import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchPlayerList = createAsyncThunk(
  'players/playerList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminPlayerList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerDetail = createAsyncThunk(
  'players/playerDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminPlayerDetail', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerTransactionHistory = createAsyncThunk(
  'players/playerTransactionHistory',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminPlayerTransactionHistory', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerTicketHistory = createAsyncThunk(
  'players/playerTicketHistory',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminPlayerTicketHistory', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editPlayer = createAsyncThunk(
  'players/editPlayer',
  async (playerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('adminEditPlayer', playerData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchRecruiterList = createAsyncThunk(
  'players/recruiterList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminRecruiterList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchRecruiterDetail = createAsyncThunk(
  'players/recruiterDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminRecruiterDetail', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
