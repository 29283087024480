import { useState } from 'react';
import { useSelector } from 'react-redux';
import VideoLinkModal from '../Modal/VideoLinkModal';
import { enqueueSnackbar } from 'notistack';

const AnnouncementList = () => {
  const selectedDrawdownInfo = useSelector(
    (state) => state.drawdown.selectedDrawdownInfo
  );
  const [modalSection, setModalSection] = useState('');
  const [selectedAnnId, setSelectedAnnId] = useState(null);

  return (
    <>
      {modalSection === 'edit_announcement' ? (
        <VideoLinkModal
          closeModal={setModalSection}
          selectedIdx={selectedAnnId}
        />
      ) : modalSection === 'delete_announcement' ? (
        <VideoLinkModal
          closeModal={setModalSection}
          selectedIdx={selectedAnnId}
          isDelete={true}
        />
      ) : null}
      {selectedDrawdownInfo.announcement_list &&
      selectedDrawdownInfo.announcement_list.length ? (
        <div className="flex flex-col">
          {selectedDrawdownInfo.announcement_list
            .filter((ann) => !ann.is_deleted)
            .map((filteredAnn, index) => (
              <div className="flex flex-col" key={filteredAnn.announcement_id}>
                <div className="flex flex-row items-center justify-between mt-[12px]">
                  <h3 className="text-sm font-poppins-semibold w-[70%]">
                    {filteredAnn.video_title}
                  </h3>
                  <div className="flex flex-row gap-[4px]">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedAnnId(index);
                        setModalSection('edit_announcement');
                      }}
                    >
                      <ion-icon
                        name="create-outline"
                        style={{ width: '18px', height: '18px' }}
                      ></ion-icon>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedAnnId(index);
                        setModalSection('delete_announcement');
                      }}
                    >
                      <ion-icon
                        name="trash-outline"
                        style={{ width: '18px', height: '18px' }}
                      ></ion-icon>
                    </button>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between mt-[8px]">
                  <p className="text-sm w-[70%]">{filteredAnn.video_link}</p>
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(filteredAnn.video_link);
                      enqueueSnackbar('Sucessfully copied video link', {
                        variant: 'success',
                      });
                    }}
                  >
                    <ion-icon
                      name="copy-outline"
                      style={{ width: '18px', height: '18px' }}
                    ></ion-icon>
                  </button>
                </div>
                {filteredAnn.announcement ? (
                  <div className="flex flex-col mt-[12px]">
                    <h3 className="text-sm font-poppins-semibold w-[70%]">
                      Announcement
                    </h3>
                    <p className="text-sm mt-[8px]">
                      {filteredAnn.announcement}
                    </p>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
};

export default AnnouncementList;
