import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import Logo from '../assets/imgs/logo.png';
import Input from '../components/Input/Input';
import Loading from '../components/Common/Loading';
import getToastMessage from '../utils/toastMessage';
import { forgotPassword } from '../utils/validationForm';
import { forgotPasswordStep3, forgotPasswordStep2 } from '../actions/auth';

const ChangePasswordPage = () => {
  const { isLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(forgotPassword),
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    if (!isLoading) {
      const forgotPassData = JSON.parse(JSON.stringify(data));
      forgotPassData.token = searchParams.get('t');
      const done = await dispatch(forgotPasswordStep3(forgotPassData));

      if (done) {
        const { message, variant } = getToastMessage(done);
        enqueueSnackbar(message, { variant });
      }
    }
  };

  const validateToken = async (token) => {
    const done = await dispatch(forgotPasswordStep2(token));
    if (done) {
      const { message, variant } = getToastMessage(done);
      enqueueSnackbar(message, { variant });
      // if (variant === 'error') navigate('/login');
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';

    const token = searchParams.get('t');
    if (token) validateToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="flex bg-white-f flex-col justify-center items-center w-[80%] sm:w-[30%] m-auto h-[100vh]">
        <div>
          <img src={Logo} alt="lucky cat logo" />
        </div>
        <h4 className="mt-[16px] font-poppins-bold text-[20px]">
          Lucky Cat 88 Admin
        </h4>
        <p className="text-sm mb-[16px]">Change Password</p>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full mx-auto">
          <Input
            type="password"
            title="New Password"
            placeholder="Password"
            register={register}
            name="new_password"
            errors={formState.errors}
          />
          <Input
            title="Confirm New Password"
            placeholder="Password"
            type="password"
            name="confirm_new_password"
            register={register}
            errors={formState.errors}
          />
          <button
            className="bg-black-100 text-white text-base font-poppins-medium w-full py-[8px] rounded-[20px] mt-[12px]"
            type="submit"
          >
            Change Password
          </button>
        </form>
      </div>
    </>
  );
};

export default ChangePasswordPage;
