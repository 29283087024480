import Modal from '../Modal/Modal';

const WinnerInfoModal = ({ closeModal }) => {
  return (
    <Modal title="Winner Information" closeModal={closeModal}>
      <div>
        <ul className="text-sm ml-[20px] list-decimal">
          <li>
            When a user wins, their account will automatically be credited with
            the amount of CKU that they won.
          </li>
          <li>
            If they have a referrer, the referrer also earns 5% of their
            winnings, but this is not taken out of the winner’s balance. This
            will be taken out of the 5% we set aside for the referrer’s
            winnings.
          </li>
          <li>
            If there are more than 1 winner, then the amount that’s set aside is
            divided equally by all the winners. For example if there are 10
            winners of the second prize, and the second prize is worth 1000 CKU,
            then the 1000 CKU is divided by the 10 winners. Each winner will get
            100 CKU.
          </li>
          <li>
            A Start/Pause Button for automatically adding: CKU to each pot from
            ticket sales.
            <ul className="list-[lower-alpha] ml-[20px]">
              <li>Start/Pause button for first place prize.</li>
              <li>Start/Pause button for second place prize.</li>
              <li>Start/Pause button for third place prize.</li>
            </ul>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default WinnerInfoModal;
