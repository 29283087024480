import StyledTable from '../Table/StyledTable';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTableCell from '../Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../Table/Pagination';
import { thousandSeparator } from '../../utils/math';
import {
  resetPageData,
  setDetailPageData,
  setTicketPageData,
} from '../../slices/playersSlice';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import EditPlayerModal from './EditPlayerModal';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataLimit from '../Table/DataLimit';
import Wallet from '../Wallet/Wallet';

const PlayerBasicInfo = () => {
  const {
    selectedPlayerDetail,
    selectedSeasonSummary,
    seasonPages,
    detailPageData,
  } = useSelector((state) => state.players);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [sendLCModal, setSendLCModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const fields = [
    {
      placeholder: 'Email',
      field: 'email',
    },
    {
      placeholder: 'Player ID',
      field: 'player_id',
    },
    {
      placeholder: 'Phone',
      field: 'phone_number',
    },
    {
      placeholder: 'Player Status',
      field: 'status',
    },
    {
      placeholder: 'Registered Date',
      field: 'createdAt',
    },
    {
      placeholder: 'CKU Wallet Balance',
      field: 'lucky_coin_balance',
    },
    {
      placeholder: 'Amount Purchase',
      field: 'total_tickets',
    },
    {
      placeholder: 'Win History',
      field: 'winning_tickets',
    },
    {
      placeholder: 'Referral Link',
      field: 'referral_tag',
    },
  ];

  const onSearchSeason = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== detailPageData.search) {
      const search = ev.target.value;
      dispatch(setDetailPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== detailPageData.season_page) {
      dispatch(setDetailPageData({ season_page: ev.selected }));
    }
  };

  const goToRewardPage = (seasonID) => {
    navigate(`/lottery/season/${seasonID}`);
  };

  useEffect(() => {
    return () => {
      dispatch(resetPageData('detailPageData'));
    };
  }, [dispatch]);

  return (
    <>
      {editModal ? (
        <EditPlayerModal closeModal={setEditModal} playerDetail={editModal} />
      ) : null}
      {sendLCModal ? (
        <Wallet
          defaultSection="Send CKU"
          closeModal={setSendLCModal}
          playerEmail={selectedPlayerDetail.email}
        />
      ) : null}
      {selectedPlayerDetail ? (
        <div>
          <div className="flex flex-col sm:flex-row sm:items-center font-poppins-semibold">
            <h3 className="text-base">Basic Info</h3>
            <div className="flex flex-row mt-[16px] sm:mt-0 sm:ml-[18%]">
              <button
                type="button"
                onClick={() => {
                  setEditModal({
                    _id: selectedPlayerDetail._id,
                    player_id: selectedPlayerDetail.player_id,
                    status: selectedPlayerDetail.status,
                    email: selectedPlayerDetail.email,
                    phone_number: selectedPlayerDetail.phone_number,
                  });
                }}
                className="px-[16px] py-[6px] border border-black-100 bg-white rounded-[20px] mr-[16px]"
              >
                Edit Player
              </button>
              <button
                type="button"
                onClick={() => setSendLCModal(true)}
                className="px-[16px] py-[6px] border border-black-100 bg-white rounded-[20px]"
              >
                Send CKU
              </button>
            </div>
          </div>
          <div className="mt-[16px] mb-[24px]">
            {fields.map((field, idx) => (
              <div
                className="flex flex-row items-center w-full mb-[8px] text-sm"
                key={idx}
              >
                <label className="w-[45%] lg:w-[25%] font-poppins-semibold">
                  {field.placeholder}
                </label>
                <p
                  className={
                    field.field === 'total_tickets' ||
                    field.field === 'winning_tickets'
                      ? 'underline cursor-pointer'
                      : ''
                  }
                  onClick={() => {
                    if (
                      field.field === 'total_tickets' ||
                      field.field === 'winning_tickets'
                    ) {
                      setSearchParams({ player_section: 'Ticket History' });
                      if (field.field === 'winning_tickets')
                        dispatch(setTicketPageData({ ticket_status: 'Win' }));
                    }
                  }}
                >
                  {field.field === 'createdAt'
                    ? moment(selectedPlayerDetail.createdAt).format(
                        'DD-MM-YYYY, HH:mm:ss'
                      )
                    : field.field === 'lucky_coin_balance'
                    ? `CKU ${thousandSeparator(
                        selectedPlayerDetail[field.field]
                      )}`
                    : field.field === 'winning_tickets'
                    ? selectedPlayerDetail[field.field].length
                    : selectedPlayerDetail[field.field]}
                  {field.field === 'total_tickets' ||
                  field.field === 'winning_tickets'
                    ? ' ticket(s)'
                    : ''}
                </p>
                {field.field === 'referrer_tag' &&
                selectedPlayerDetail[field.field] ? (
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedPlayerDetail.referrer_tag
                      );
                      enqueueSnackbar('Sucessfully copied referral link', {
                        variant: 'success',
                      });
                    }}
                    className="flex ml-[5px]"
                  >
                    <ion-icon
                      name="copy-outline"
                      style={{ width: '18px', height: '18px' }}
                    ></ion-icon>
                  </button>
                ) : null}
              </div>
            ))}
          </div>
          <div className="pt-[24px] border-t border-t-[#ccc]">
            <h3 className="font-poppins-semibold text-base">Season Summary</h3>
            {selectedSeasonSummary && selectedSeasonSummary.length ? (
              <div>
                <div className="flex flex-col mb-[24px] mt-[16px]">
                  <label className="text-sm ">Search</label>
                  <input
                    placeholder="Season"
                    onKeyDown={onSearchSeason}
                    className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
                  />
                </div>
                <DataLimit
                  initialLimit={detailPageData.dataLimit}
                  setter={setDetailPageData}
                  pageState="season_page"
                  classList="mb-[16px]"
                />
                <StyledTableContainer>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Season</StyledTableCell>
                        <StyledTableCell>Draw ID</StyledTableCell>
                        <StyledTableCell>CKU Spent</StyledTableCell>
                        <StyledTableCell>CKU Won</StyledTableCell>
                        <StyledTableCell>Tickets Purchased</StyledTableCell>
                        <StyledTableCell>Winning Tickets</StyledTableCell>
                        <StyledTableCell
                          sx={{ textAlign: 'center', minWidth: '131px' }}
                        >
                          Action
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedSeasonSummary.map((season) => (
                        <TableRow
                          key={`${season.lottery_season_id}-${season.drawdown_id}`}
                        >
                          <StyledTableCell>
                            {season.lottery_season_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {season.drawdown_id}
                          </StyledTableCell>
                          <StyledTableCell>
                            {thousandSeparator(season.lucky_coin_spent)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {thousandSeparator(season.lucky_coin_winnings)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {season.total_tickets}
                          </StyledTableCell>
                          <StyledTableCell>
                            {season.winning_tickets}
                          </StyledTableCell>
                          <StyledTableCell sx={{ textAlign: 'center' }}>
                            <button
                              onClick={() =>
                                goToRewardPage(season.lottery_season_id)
                              }
                              className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                            >
                              Reward Page
                            </button>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
                <Pagination
                  totalPages={seasonPages}
                  pageData={{ page: detailPageData.season_page }}
                  onChange={handlePageChange}
                />
              </div>
            ) : (
              <p className="text-sm">No summary data found!</p>
            )}
          </div>
        </div>
      ) : (
        <p>Player data not found!</p>
      )}
    </>
  );
};

export default PlayerBasicInfo;
