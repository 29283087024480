import { useEffect } from 'react';
import Modal from '../Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { editPrize } from '../../utils/validationForm';
import { editPrizeDistribution } from '../../actions/drawdown';
import PrizeDistribution from './PrizeDistribution';

const EditPrizeDistribution = ({ closeModal }) => {
  const { register, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(editPrize),
  });
  const dispatch = useDispatch();
  const { seasonID, selectedDrawdownID } = useSelector(
    (state) => state.drawdown
  );

  const onSubmit = async (data) => {
    const done = await dispatch(editPrizeDistribution(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };

  useEffect(() => {
    var data = {
      lottery_season_id: seasonID ? seasonID : '',
      drawdown_id: selectedDrawdownID ? selectedDrawdownID : '',
    };

    reset(data);
  }, [reset, seasonID, selectedDrawdownID]);

  return (
    <Modal closeModal={closeModal} title="Edit Prize Distribution">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PrizeDistribution
          setValue={setValue}
          register={register}
          watch={watch}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => reset()}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditPrizeDistribution;
