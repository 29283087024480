import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { nextDrawDown } from '../../utils/validationForm';
import { createNextDrawdown } from '../../actions/drawdown';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import AdminCurrentLC from '../Admin/AdminCurrentLC';
import PrizeDistribution from './PrizeDistribution';
import { useSearchParams } from 'react-router-dom';

const NextDrawModal = ({ closeModal }) => {
  const { register, handleSubmit, reset, formState, watch, setValue } = useForm(
    {
      resolver: yupResolver(nextDrawDown),
    }
  );
  const dispatch = useDispatch();
  const { seasonID, latestDrawdownInfo } = useSelector(
    (state) => state.drawdown
  );
  const selectedSection = useSelector(
    (state) => state.seasonSection.selectedSection
  );
  const [, setSearchParams] = useSearchParams();

  const onSubmit = async (data) => {
    console.log(data, '====data');
    const done = await dispatch(createNextDrawdown(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        setSearchParams({
          season_section: selectedSection,
          draw_id: done.payload.details.drawdown.id,
        });
        closeModal(false);
      }
    }
  };

  useEffect(() => {
    var data = {
      lottery_season_id: seasonID ? seasonID : '',
      current_drawdown_id: latestDrawdownInfo
        ? latestDrawdownInfo.drawdown.id
        : '',
      first_prize: latestDrawdownInfo
        ? latestDrawdownInfo.prize_list[0].prize_distribution
        : 0,
      second_prize: latestDrawdownInfo
        ? latestDrawdownInfo.prize_list[1].prize_distribution
        : 0,
      third_prize: latestDrawdownInfo
        ? latestDrawdownInfo.prize_list[2].prize_distribution
        : 0,
    };

    reset(data);
  }, [reset, seasonID, latestDrawdownInfo]);
  return (
    <Modal title="Next Draw Down" closeModal={closeModal}>
      <AdminCurrentLC type="winning" useLatest={true} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          title="Next Draw Down Name"
          name="drawdown_name"
          placeholder="Name"
          errors={formState.errors}
        />
        <PrizeDistribution
          setValue={setValue}
          register={register}
          watch={watch}
        />
        <p className="text-sm">
          <span className="font-poppins-semibold">Note: </span>All of the
          setting from previous session will be copy to the next draw.
        </p>
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => reset()}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NextDrawModal;
