import { useForm, Controller } from 'react-hook-form';
import Input from '../Input/Input';
import Modal from './Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { rewardVideoLink } from '../../utils/validationForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUpdateAnnouncement } from '../../actions/drawdown';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';

const VideoLinkModal = ({ closeModal, selectedIdx = null, isDelete }) => {
  const { control, handleSubmit, reset, register, formState, getValues } =
    useForm({
      resolver: yupResolver(rewardVideoLink),
    });
  const { seasonID, selectedDrawdownID, selectedDrawdownInfo } = useSelector(
    (state) => state.drawdown
  );
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    // console.log(data);
    const done = await dispatch(createUpdateAnnouncement(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        closeModal(false);
        enqueueSnackbar(message, { variant });
      }
    }
  };

  useEffect(() => {
    var data = {
      lottery_season_id: seasonID ? seasonID : '',
      drawdown_id: selectedDrawdownID ? selectedDrawdownID : '',
    };

    if (
      selectedDrawdownInfo &&
      selectedDrawdownInfo.announcement_list &&
      selectedDrawdownInfo.announcement_list.length &&
      selectedIdx !== null
    ) {
      var announcement = JSON.parse(
        JSON.stringify(selectedDrawdownInfo.announcement_list[selectedIdx])
      );
      announcement.announcement_id = announcement._id;
      delete announcement._id;
      if (isDelete) announcement.is_deleted = true;
      data = { ...data, ...announcement };
    }
    reset(data);
  }, [
    seasonID,
    selectedDrawdownID,
    reset,
    selectedIdx,
    selectedDrawdownInfo,
    isDelete,
  ]);

  return (
    <>
      {isDelete ? (
        <Modal closeModal={closeModal} title="Delete">
          <p className="text-sm">Are you sure you want to delete this?</p>
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              onClick={() => closeModal()}
              type="button"
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              Discard All
            </button>
            <button
              type="button"
              onClick={() => {
                const data = getValues();
                onSubmit(data);
              }}
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              Delete
            </button>
          </div>
        </Modal>
      ) : (
        <Modal title="Video Link" closeModal={closeModal}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="video_title"
              title="Video Title"
              placeholder="Input"
              register={register}
              errors={formState.errors}
            />
            <Input
              name="video_link"
              title="Video Link"
              placeholder="https://"
              register={register}
              errors={formState.errors}
            />
            <div>
              <label className="text-sm">Announcement (optional)</label>
              <Controller
                control={control}
                name="announcement"
                rules={{ required: true }}
                render={({ field }) => (
                  <textarea
                    {...field}
                    onChange={field.onChange}
                    className="p-[8px] bg-white border border-black-100 outline-none text-sm rounded-md w-full min-h-[100px]"
                  />
                )}
              />
            </div>
            <div className="flex flex-row justify-between items-center mt-[24px]">
              <button
                onClick={() => reset()}
                type="button"
                className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
              >
                Discard All
              </button>
              <button
                type="submit"
                className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
              >
                Save
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default VideoLinkModal;
