import { createSlice } from '@reduxjs/toolkit';
import { fetchTicketDetail, fetchTicketList } from '../actions/ticket';

const initialState = {
  ticketList: [],
  selectedTicketInfo: null,
  selectedTicketSeasonId: '',
  totalPages: 0,
  pageData: {
    page: 0,
    search: '',
    season_filter: 'All',
    ticket_filter: 'All',
    startDate: null,
    endDate: null,
    dataLimit: 10,
  },
  isLoading: false,
};

export const ticket = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
  },
  extraReducers: {
    [fetchTicketList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTicketList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.ticketList = payload.ticket_list;
      state.totalPages = payload.pages;
    },
    [fetchTicketList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchTicketDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTicketDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedTicketInfo = payload.ticket;
      state.selectedTicketSeasonId = payload.lottery_season_id;
    },
    [fetchTicketDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading, setPageData } = ticket.actions;

export default ticket.reducer;
