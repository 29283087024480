import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPageData } from '../slices/seasonSlice';
import { editSeasonBanner, fetchSeasonList } from '../actions/season';
import moment from 'moment';
import { TableBody, TableHead, TableRow } from '@mui/material';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import StyledTableCell from '../components/Table/StyledTableCell';
import StyledSwitch from '../components/Button/StyledSwitch';
import SeasonStatus from '../components/Season/SeasonStatus';
import SeasonNewModal from '../components/Season/SeasonNewModal';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTable from '../components/Table/StyledTable';
import Loading from '../components/Common/Loading';
import Pagination from '../components/Table/Pagination';
import getToastMessage from '../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { clearState } from '../slices/drawdownSlice';
import { setSelectedSession } from '../slices/seasonSectionSlice';
import DataLimit from '../components/Table/DataLimit';

const LotteryPage = () => {
  const [openNewSeasonModal, setOpenNewSeasonModal] = useState(false);
  const { isLoading, seasonList, pageData, totalPages } = useSelector(
    (state) => state.season
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onUpdateBannerStatus = async (season) => {
    let seasonParse = JSON.parse(JSON.stringify(season));
    seasonParse.banner.is_active = seasonParse.banner.is_active ? false : true;
    const seasonData = {
      lottery_season_id: seasonParse.lottery_season.id,
      banner: seasonParse.banner,
    };
    const done = await dispatch(editSeasonBanner(seasonData));
    if (done) {
      // dispatch(fetchSeasonList(pageData));
      const { message, variant } = getToastMessage(done);
      enqueueSnackbar(message, { variant });
    }
  };

  const goToSeasonDetail = (seasonID) => {
    navigate(`/lottery/season/${seasonID}`);
  };

  const onSearchSeason = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== pageData.search) {
      const search = ev.target.value;
      dispatch(setPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== pageData.page) {
      dispatch(setPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchSeasonList(pageData));
  }, [dispatch, pageData]);

  useEffect(() => {
    dispatch(setSelectedSession('season'));
    dispatch(clearState());
  }, [dispatch]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {openNewSeasonModal ? (
        <SeasonNewModal closeModal={setOpenNewSeasonModal} />
      ) : null}
      <LayoutAdmin pageTitle="Lottery Management" pageSubtitle="List of Season">
        <div className="flex flex-col mb-[24px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Season Name, Season ID or Status"
            onKeyDown={onSearchSeason}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
          <DataLimit initialLimit={pageData.dataLimit} setter={setPageData} />
          <button
            onClick={() => setOpenNewSeasonModal(true)}
            className="px-[16px] py-[8px] bg-black-100 text-white rounded-[20px] font-poppins-semibold ml-auto block"
          >
            Create New Season
          </button>
        </div>
        <div className="mt-[16px]">
          {seasonList && seasonList.length && !isLoading ? (
            <div>
              <StyledTableContainer>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Season Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Start Date</StyledTableCell>
                      <StyledTableCell>Season ID</StyledTableCell>
                      <StyledTableCell>Reward</StyledTableCell>
                      <StyledTableCell>Winners</StyledTableCell>
                      <StyledTableCell>Home Banners</StyledTableCell>
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {seasonList.map((season) => (
                      <TableRow key={season.lottery_season.id}>
                        <StyledTableCell>
                          {season.lottery_season.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {<SeasonStatus status={season.status} />}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment(season.startDate).format('MMMM D, YYYY')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {season.lottery_season.id}
                        </StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                        <StyledTableCell>{season.winner_count}</StyledTableCell>
                        <StyledTableCell>
                          {season.status === 'Active' ? (
                            <StyledSwitch
                              onChange={() => onUpdateBannerStatus(season)}
                              checked={season.banner && season.banner.is_active}
                            />
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: 'center' }}>
                          <button
                            onClick={() =>
                              goToSeasonDetail(season.lottery_season.id)
                            }
                            className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                          >
                            Detail
                          </button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={totalPages}
                pageData={pageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <h3 className="text-center mt-[5rem] text-base">
              No season data found
            </h3>
          )}
        </div>
      </LayoutAdmin>
    </>
  );
};

export default LotteryPage;
