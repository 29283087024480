import { configureStore } from '@reduxjs/toolkit';
import navbarReducer from './slices/navbarSlice';
import seasonSectionReducer from './slices/seasonSectionSlice';
import authReducer from './slices/authSlice';
import adminReducer from './slices/adminSlice';
import seasonReducer from './slices/seasonSlice';
import imageUploadReducer from './slices/imageUploadSlice';
import contactReducer from './slices/contactSlice';
import contentReducer from './slices/contentSlice';
import playersReducer from './slices/playersSlice';
import dashboardReducer from './slices/dashboardSlice';
import drawdownReducer from './slices/drawdownSlice';
import ticketReducer from './slices/ticketSlice';
import coinsReducer from './slices/coinsSlice';
import walletReducer from './slices/walletSlice';
import payoutReducer from './slices/payoutSlice';

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    seasonSection: seasonSectionReducer,
    auth: authReducer,
    admin: adminReducer,
    season: seasonReducer,
    imageUpload: imageUploadReducer,
    contact: contactReducer,
    content: contentReducer,
    players: playersReducer,
    dashboard: dashboardReducer,
    drawdown: drawdownReducer,
    ticket: ticketReducer,
    coins: coinsReducer,
    wallet: walletReducer,
    payout: payoutReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
