import Modal from '../Modal/Modal';

export const AnnouncementInfo = ({ closeModal }) => {
  return (
    <Modal title="Announcement Information" closeModal={closeModal}>
      <ul className="text-sm ml-[20px] list-decimal">
        <li>
          Only Super Admin can input the lottery numbers that are announced.
        </li>
        <li>
          <span className="font-poppins-bold">Message: </span>
          Admin can add additional announcement, e.i for date and time when
          first lottery numbers will be announced.
        </li>
      </ul>
    </Modal>
  );
};

export default AnnouncementInfo;
