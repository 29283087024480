import { useDispatch, useSelector } from 'react-redux';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import Loading from '../components/Common/Loading';
import StyledTable from '../components/Table/StyledTable';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTableCell from '../components/Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../components/Table/Pagination';
import { fetchRecruiterList } from '../actions/players';
import { setRecruiterPageData } from '../slices/playersSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataLimit from '../components/Table/DataLimit';

const RecruitersPage = () => {
  const { isLoading, recruiterList, recruiterPages, recruiterPageData } =
    useSelector((state) => state.players);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSearchPlayer = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== recruiterPageData.search) {
      const search = ev.target.value;
      dispatch(setRecruiterPageData({ search }));
    }
  };

  const goToPlayerDetail = (id) => {
    navigate(`/recruiter/${id}`);
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== recruiterPageData.page) {
      dispatch(setRecruiterPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchRecruiterList(recruiterPageData));
  }, [dispatch, recruiterPageData]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <LayoutAdmin pageSubtitle="Recruiters" pageTitle="Recruiter List">
        <div className="flex flex-col mb-[24px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Email or Link"
            onKeyDown={onSearchPlayer}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <div className="mt-[16px]">
          {recruiterList && recruiterList.length && !isLoading ? (
            <div>
              <DataLimit
                initialLimit={recruiterPageData.dataLimit}
                setter={setRecruiterPageData}
                classList="mb-[16px]"
              />
              <StyledTableContainer sx={{ width: ['100%', '80%', '80%'] }}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Link</StyledTableCell>
                      <StyledTableCell>Member Recruited</StyledTableCell>
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recruiterList.map((recruiter) => (
                      <TableRow key={recruiter._id}>
                        <StyledTableCell>{recruiter.email}</StyledTableCell>
                        <StyledTableCell>
                          {recruiter.referrer_tag}
                        </StyledTableCell>
                        <StyledTableCell>
                          {recruiter.referral_count}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: 'center' }}>
                          <button
                            onClick={() =>
                              goToPlayerDetail(recruiter.player_id)
                            }
                            className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                          >
                            Detail
                          </button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={recruiterPages}
                pageData={recruiterPageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <h3 className="text-center mt-[5rem] text-base">
              No players data found
            </h3>
          )}
        </div>
      </LayoutAdmin>
    </>
  );
};

export default RecruitersPage;
