import { useEffect, useState } from 'react';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Common/Loading';
import { setPageData, setSelectedPayout } from '../slices/payoutSlice';
import StyledTable from '../components/Table/StyledTable';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTableCell from '../components/Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../components/Table/Pagination';
import moment from 'moment';
import DataLimit from '../components/Table/DataLimit';
import { fetchPayoutList } from '../actions/payout';
import { thousandSeparator } from '../utils/math';
import TransferProofModal from '../components/Modal/TransferProofModal';

const PayoutPage = () => {
  const { isLoading, payoutList, pageData, totalPages } = useSelector(
    (state) => state.payout
  );
  const [showTfModal, setShowTfModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPayoutList(pageData));
  }, [dispatch, pageData]);

  useEffect(() => {
    if (!showTfModal) {
      dispatch(setSelectedPayout(null));
    }
  }, [dispatch, showTfModal]);

  const onSearchPayout = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== pageData.search) {
      const search = ev.target.value;

      dispatch(setPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== pageData.page) {
      dispatch(setPageData({ page: ev.selected }));
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {showTfModal ? <TransferProofModal closeModal={setShowTfModal} /> : null}
      <LayoutAdmin pageSubtitle="Cash Winner" pageTitle="Cash Winner">
        <div className="flex flex-col mb-[24px]">
          <label className="text-sm ">Search</label>
          <input
            onKeyDown={onSearchPayout}
            placeholder="Winner Email, Winner Phone Number"
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <DataLimit
          initialLimit={pageData.dataLimit}
          setter={setPageData}
          classList="mb-[16px]"
        />
        <div className="mt-[12px]">
          {payoutList && payoutList.length && !isLoading ? (
            <div className="flex flex-col">
              <StyledTableContainer>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Transaction Date</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Prize Type</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Winner E-Mail</StyledTableCell>
                      <StyledTableCell>Season</StyledTableCell>
                      <StyledTableCell>Drawdown</StyledTableCell>
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        Proof of Transfer
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payoutList.map((payout) => (
                      <TableRow key={payout._id}>
                        <StyledTableCell>
                          {moment(payout.createdAt).format(
                            'DD-MM-YYYY, HH:mm:ss'
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {payout.is_paid ? 'Paid' : 'Unpaid'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {payout.transaction_type}
                        </StyledTableCell>
                        <StyledTableCell>
                          Rp {thousandSeparator(payout.total_payment)}
                        </StyledTableCell>
                        <StyledTableCell>{payout.player_email}</StyledTableCell>
                        <StyledTableCell>
                          {payout.lottery_season.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {payout.drawdown.name}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: 'center' }}>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              dispatch(setSelectedPayout(payout));
                              setShowTfModal(true);
                            }}
                          >
                            {payout.proof_of_payment &&
                            payout.proof_of_payment.image ? (
                              <figure className="w-auto h-[100px]">
                                <img
                                  src={payout.proof_of_payment.image}
                                  alt={payout.transaction_id}
                                  className="w-full h-full object-contain"
                                />
                              </figure>
                            ) : (
                              <button
                                className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                                type="button"
                              >
                                Upload
                              </button>
                            )}
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={totalPages}
                pageData={pageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <h3 className="text-center mt-[5rem] text-base">
              No cash winner data found
            </h3>
          )}
        </div>
      </LayoutAdmin>
    </>
  );
};

export default PayoutPage;
