import { useForm } from 'react-hook-form';
import Modal from '../Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { winningNumber } from '../../utils/validationForm';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import WinNumbersConfirmModal from './WinNumbersConfirmModal';
import { areAllUnique } from '../../utils/math';
import { enqueueSnackbar } from 'notistack';

const WinningNumbersModal = ({ closeModal }) => {
  const { handleSubmit, reset, register, setValue } = useForm({
    resolver: yupResolver(winningNumber),
  });
  const { seasonID, selectedDrawdownID } = useSelector(
    (state) => state.drawdown
  );
  const selectedSeasonInfo = useSelector(
    (state) => state.season.selectedSeasonInfo
  );
  const [formPayload, setFormPayload] = useState(null);
  const numbers = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];

  const onSubmit = async (data) => {
    const {
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      drawdown_id,
      lottery_season_id,
      extra_number,
    } = data;
    const winNumbers = [first, second, third, fourth, fifth, sixth];
    const tempWinNumbers = [...winNumbers, extra_number];
    if (!areAllUnique(tempWinNumbers)) {
      enqueueSnackbar('Winning numbers should consist of unique numbers', {
        variant: 'warning',
      });
    } else {
      const payload = {
        drawdown_id,
        lottery_season_id,
        number_list: winNumbers,
        extra_number,
      };

      setFormPayload(payload);
    }
  };

  useEffect(() => {
    setValue('lottery_season_id', seasonID ? seasonID : '');
    setValue('drawdown_id', selectedDrawdownID ? selectedDrawdownID : '');
  }, [seasonID, selectedDrawdownID, setValue]);

  useEffect(() => {
    if (formPayload === 1) closeModal(false);
  }, [closeModal, formPayload]);

  return (
    <>
      {formPayload ? (
        <WinNumbersConfirmModal
          closeModal={setFormPayload}
          payload={formPayload}
        />
      ) : null}
      <Modal title="Add Win Numbers" closeModal={closeModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col mb-[24px]">
            <label className="text-sm">Win Lottery Number</label>
            <div className="flex flex-row w-full gap-[8px]">
              {numbers.map((num) => (
                <input
                  key={num}
                  type="number"
                  {...register(num, {
                    onChange: (e) => {
                      const val = e.target.value;
                      const maxLength = String(
                        selectedSeasonInfo.max_number
                      ).length;
                      if (val.length <= maxLength) {
                        if (
                          val.length === 1 &&
                          +val < selectedSeasonInfo.min_number
                        ) {
                          setValue(num, null);
                        } else if (+val > selectedSeasonInfo.max_number) {
                          setValue(num, +val.substring(0, 1));
                        }
                      }
                      if (val.length > maxLength)
                        setValue(num, +val.substring(0, maxLength));
                    },
                  })}
                  className="text-sm w-[26px] h-[26px] text-center font-poppins-bold border border-black-100 rounded outline-0"
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-sm">Winning Extra Number</label>
            <div className="flex flex-row w-full gap-[8px]">
              <input
                type="number"
                {...register('extra_number', {
                  onChange: (e) => {
                    const val = e.target.value;
                    const maxLength = String(
                      selectedSeasonInfo.max_number
                    ).length;
                    if (val.length <= maxLength) {
                      if (
                        val.length === 1 &&
                        +val < selectedSeasonInfo.min_number
                      ) {
                        setValue('extra_number', null);
                      } else if (+val > selectedSeasonInfo.max_number) {
                        setValue('extra_number', +val.substring(0, 1));
                      }
                    }
                    if (val.length > maxLength)
                      setValue('extra_number', +val.substring(0, maxLength));
                  },
                })}
                className="text-sm w-[26px] h-[26px] text-center font-poppins-bold border border-black-100 rounded outline-0"
              />
            </div>
          </div>
          <div className="flex flex-col text-xs text-black-70 mt-[24px]">
            <p>Min Number: {selectedSeasonInfo.min_number}</p>
            <p>Max Number: {selectedSeasonInfo.max_number}</p>
          </div>
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              onClick={() => reset()}
              type="button"
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              Discard All
            </button>
            <button
              type="submit"
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default WinningNumbersModal;
