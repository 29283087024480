import { useEffect, useState } from 'react';
import { fetchDrawdownWinnerList, fetchSeasonInfo } from '../actions/season';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import SeasonInfo from '../components/Season/SeasonInfo';
import SeasonReward from '../components/Season/SeasonReward';
import SeasonWinner from '../components/Season/SeasonWinner';
import { fetchDrawdownInfo, fetchDrawdownList } from '../actions/drawdown';
import { setSelectedSession } from '../slices/seasonSectionSlice';
import { setSelectedDrawdownID } from '../slices/drawdownSlice';

const SeasonDetailPage = () => {
  const selectedSection = useSelector(
    (state) => state.seasonSection.selectedSection
  );
  const selectedSeasonInfo = useSelector(
    (state) => state.season.selectedSeasonInfo
  );
  const [pageSubtitle, setPageSubtitle] = useState(
    'List of Season, Season Detail'
  );
  const { selectedDrawdownID } = useSelector((state) => state.drawdown);
  const winnerPageData = useSelector((state) => state.season.winnerPageData);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (id) {
      if (selectedSection === 'season') {
        dispatch(fetchSeasonInfo({ id: id }));
      } else if (
        selectedSection === 'reward' ||
        selectedSection === 'winners'
      ) {
        dispatch(fetchDrawdownList({ lottery_season_id: id }));
      }
    }
  }, [id, dispatch, selectedSection]);

  useEffect(() => {
    if (selectedDrawdownID && selectedSection === 'reward') {
      dispatch(
        fetchDrawdownInfo({
          drawdown_id: selectedDrawdownID,
          lottery_season_id: id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrawdownID, selectedSection]);

  useEffect(() => {
    if (selectedDrawdownID && selectedSection === 'winners') {
      dispatch(
        fetchDrawdownWinnerList({
          lottery_season_id: id,
          drawdown_id: selectedDrawdownID,
          ...winnerPageData,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrawdownID, selectedSection, winnerPageData]);

  useEffect(() => {
    const section = searchParams.get('season_section');
    const drawID = searchParams.get('draw_id');
    // console.log(drawID, '===drawID');
    // console.log(section, '====section');
    if (section) {
      dispatch(setSelectedSession(section));
    }
    if (drawID) {
      dispatch(setSelectedDrawdownID(drawID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (selectedSeasonInfo) {
      setPageSubtitle(
        `List of Season, Season Detail "${selectedSeasonInfo.lottery_season.name}"`
      );
    }
  }, [selectedSeasonInfo]);

  useEffect(() => {
    return () => dispatch(setSelectedSession('season'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutAdmin pageTitle="Lottery Management" pageSubtitle={pageSubtitle}>
      {selectedSection === 'season' ? (
        <SeasonInfo />
      ) : selectedSection === 'reward' ? (
        <SeasonReward />
      ) : selectedSection === 'winners' ? (
        <SeasonWinner />
      ) : null}
    </LayoutAdmin>
  );
};

export default SeasonDetailPage;
