import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { editSeasonSalesPitch } from '../../actions/season';
import getToastMessage from '../../utils/toastMessage';
import { seasonSalesPitch } from '../../utils/validationForm';
import Modal from '../Modal/Modal';
import { useParams } from 'react-router-dom';

const SeasonPitchModal = ({ closeModal, pitch }) => {
  const { control, handleSubmit, reset, getFieldState, setValue } = useForm({
    resolver: yupResolver(seasonSalesPitch),
  });
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (pitch && pitch._id) {
      delete pitch.createdAt;
      reset(pitch);
    }
    setValue('lottery_season_id', id);
  }, [reset, pitch, setValue, id]);

  const onSubmit = async (data) => {
    console.log(data, '===datasales pitch');

    if (getFieldState('description')) {
      const done = await dispatch(editSeasonSalesPitch(data));
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') {
          enqueueSnackbar(message, { variant });
          closeModal(false);
        }
      }
    }
  };

  return (
    <Modal title="Add Pitch" closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="text-sm">Sales Pitch</label>
        <Controller
          control={control}
          name="description"
          rules={{ required: true }}
          render={({ field }) => (
            <textarea
              {...field}
              onChange={field.onChange}
              className="p-[8px] border bg-white border-black-100 outline-none text-sm rounded-md w-full min-h-[100px]"
            />
          )}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => reset()}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border border-black-100 rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SeasonPitchModal;
