import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DrawDownModal from './DrawDownModal';
import RewardInfoModal from './RewardInfoModal';
import Loading from '../Common/Loading';
import DrawDownDetail from './DrawDownDetail';
import DrawDownList from './DrawDownList';
import NextDrawModal from './NextDrawModal';

const SeasonReward = () => {
  const [modalSection, setModalSection] = useState('');
  const { isLoading, drawdownList } = useSelector((state) => state.drawdown);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {modalSection === 'add_draw' ? (
        <DrawDownModal closeModal={setModalSection} />
      ) : modalSection === 'reward_info' ? (
        <RewardInfoModal closeModal={setModalSection} />
      ) : modalSection === 'next_draw' ? (
        <NextDrawModal closeModal={setModalSection} />
      ) : null}
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <h3 className="text-base font-poppins-semibold">Reward </h3>
            <button
              type="button"
              className="flex ml-[4px]"
              onClick={() => setModalSection('reward_info')}
            >
              <ion-icon
                name="information-circle-outline"
                style={{ width: '16px', height: '16px' }}
              ></ion-icon>
            </button>
          </div>
          <button
            type="button"
            onClick={() =>
              setModalSection(drawdownList.length ? 'next_draw' : 'add_draw')
            }
            className="bg-white border border-black-100 w-[172px] py-[6px] font-poppins-semibold rounded-[20px]"
          >
            {drawdownList.length ? 'Next Draw Down' : 'Add Draw'}
          </button>
        </div>
        <DrawDownList />
        <DrawDownDetail />
      </div>
    </>
  );
};

export default SeasonReward;
