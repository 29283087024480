import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginAdmin = yup
  .object({
    email: yup
      .string()
      .email('Not a valid email address')
      .required('Email cannot be empty'),
    password: yup.string().required('Password cannot be empty'),
  })
  .required();

export const changePassword = yup.object({
  old_password: yup.string().required('Temporary password is required'),
  new_password: yup.string().required('New Password is required'),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], `Password doesn't match`)
    .required('Confirm Password is required'),
});

export const forgotPassword = yup.object({
  new_password: yup.string().required('New Password is required'),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], `Password doesn't match`)
    .required('Confirm Password is required'),
});

export const newAdmin = yup.object({
  name: yup.string().required('Admin name is required'),
  phone_number: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  email: yup.string().email('Not a valid email').required('Email is required'),
  role: yup.string().required('Role is required'),
  password: yup.string().required('Password is required'),
});

export const updateAdmin = yup.object({
  name: yup.string().required('Admin name is required'),
  phone_number: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  email: yup.string().email('Not a valid email').required('Email is required'),
  role: yup.string().required('Role is required'),
  password: yup.string(),
});

export const season = yup.object({
  name: yup.string().required('Season name is required'),
  startDate: yup.date().required('Season start date is required'),
  ticket_price: yup.number().required('Ticket price is required'),
  min_number: yup.number().required('Minimum number is required'),
  max_number: yup.number().required('Maximum number is required'),
  is_cash_payout: yup.boolean(),
});

export const editSeason = yup.object({
  lottery_season_id: yup.string().required(),
  name: yup.string().required('Season name is required'),
  startDate: yup.date().required('Season start date is required'),
  ticket_price: yup.number().required('Ticket price is required'),
  min_number: yup.number('Minimum number is required'),
  max_number: yup.number('Maximum number is required'),
});

export const seasonSalesPitch = yup.object({
  lottery_season_id: yup.string().required(),
  sales_pitch_id: yup.string(),
  description: yup.string().required('Description is required'),
  is_deleted: yup.boolean().default(false),
});

export const editPlayerForm = yup.object({
  _id: yup.string().required(),
  player_id: yup.string().required('Player ID is required'),
  status: yup.string().required('Player status is required'),
  phone_number: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email is required'),
});

export const sendLCForm = yup.object({
  amount: yup
    .number()
    .typeError('CKU Amount should be filled')
    .required('CKU Amount should be filled'),
  address: yup.string().required('Address is required'),
});

export const dateRangeFilter = yup.object({
  startDate: yup.date(),
  endDate: yup.date(),
});

export const newDrawDown = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_name: yup.string().required('Draw Down name is required'),
  first_prize: yup.number().required('First prize should be filled'),
  second_prize: yup.number().required('Second prize should be filled'),
  third_prize: yup.number().required('Third prize should be filled'),
});

export const topUpPrize = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_id: yup.string().required(),
  prize_number: yup.number().required(),
  topup_amount: yup.number().required('Prize is required'),
});

export const nextDrawDown = yup.object({
  lottery_season_id: yup.string().required(),
  current_drawdown_id: yup.string().required(),
  drawdown_name: yup.string().required('Draw down name is required'),
  first_prize: yup.number().required('First prize should be filled'),
  second_prize: yup.number().required('Second prize should be filled'),
  third_prize: yup.number().required('Third prize should be filled'),
});

export const editPrize = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_id: yup.string().required(),
  first_prize: yup.number().required('First prize should be filled'),
  second_prize: yup.number().required('Second prize should be filled'),
  third_prize: yup.number().required('Third prize should be filled'),
});

export const addDrawDate = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_id: yup.string().required(),
  draw_date: yup.date().required('Draw date is required'),
});

export const addFreezeDate = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_id: yup.string().required(),
  purchase_freeze_time: yup.date().required('Freeze date is required'),
});

export const rewardVideoLink = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_id: yup.string().required(),
  announcement_id: yup.string().default(''),
  video_title: yup.string().required('Video title is required'),
  video_link: yup.string().required('Video link is required'),
  announcement: yup.string(),
  is_deleted: yup.boolean().default(false).required(),
});

export const winningNumber = yup.object({
  lottery_season_id: yup.string().required(),
  drawdown_id: yup.string().required(),
  first: yup.number().required(),
  second: yup.number().required(),
  third: yup.number().required(),
  fourth: yup.number().required(),
  fifth: yup.number().required(),
  sixth: yup.number().required(),
  extra_number: yup.number().required(),
});

export const sendCoinInternal = yup.object({
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email is required'),
  lucky_coin_amount: yup
    .number()
    .typeError('CKU Amount should be filled')
    .required('CKU Amount should be filled'),
});

export const sendCoinExternal = yup.object({
  otp: yup.string().required('OTP should be filled'),
  wallet_address: yup.string().required('Wallet address should be filled'),
  lucky_coin_amount: yup
    .number()
    .typeError('CKU Amount should be filled')
    .required('CKU Amount should be filled'),
});

export const connectWallet = yup.object({
  wallet_name: yup.string().required('Name cannot be empty'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
  otp: yup.string().required('OTP cannot be empty'),
});

export const sendLC = yup.object({
  lucky_coin_amount: yup
    .number()
    .typeError('Amount should be filled')
    .required('Amount cannot be empty'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
});

export const withdrawLC = yup.object({
  lucky_coin_amount: yup
    .number()
    .typeError('Amount should be filled')
    .required('Amount cannot be empty'),
  wallet_address: yup
    .string()
    .email('Not a valid email address')
    .required('Wallet address cannot be empty'),
});

export const editWallet = yup.object({
  _id: yup.string().required(),
  wallet_name: yup.string().required('Wallet name cannot be empty'),
  wallet_address: yup
    .string()
    .email('Not a valid email address')
    .required('Wallet address cannot be empty'),
  wallet_platform: yup.string().default('latoken'),
  is_deleted: yup.boolean().default(false),
  is_active: yup.boolean().default(false),
});

export const forgotPassOTP = yup.object({
  otp: yup.string().required('OTP cannot be empty'),
});
