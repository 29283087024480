import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

const SelectPrice = ({
  selected,
  prizeNum,
  handleChange,
  setter,
  maxPercentage,
  disabled,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // console.log(maxPercentage, '====maxpercentage', maxPercentage / 5);
    if (typeof maxPercentage === 'number') {
      setOptions(
        maxPercentage <= 0
          ? Array.from({ length: 1 }, (_, index) => (index + 1) * 5)
          : Array.from(
              { length: Math.ceil(maxPercentage / 5) },
              (_, index) => (index + 1) * 5
            )
      );
    } else if (prizeNum === 'first_prize') {
      setOptions(Array.from({ length: 18 }, (_, index) => (index + 1) * 5));
    }
  }, [maxPercentage, prizeNum]);

  return (
    <Select
      value={''}
      open={selected === prizeNum}
      onClose={() => setter('')}
      onChange={(ev) => handleChange(ev, prizeNum)}
      MenuProps={{ style: { maxHeight: 200 } }}
      disabled={disabled}
      sx={{
        visibility: 'hidden',
        width: '100%',
        height: '0px',
        position: 'absolute',
        left: '0',
        bottom: '0',
      }}
    >
      {options.map((num) => (
        <MenuItem
          style={{
            fontFamily: 'Poppins',
            padding: '8px',
            minHeight: 'fit-content',
            fontSize: '12px',
          }}
          value={num}
          key={`${num}-percentage`}
        >
          {num}%
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectPrice;
