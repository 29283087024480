import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardCharts from '../components/Common/DashboardCharts';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { thousandSeparator } from '../utils/math';
import CKUIcon from '../assets/imgs/coin.png';
import { setTrsPageData } from '../slices/dashboardSlice';
import ActivePlayersModal from '../components/Modal/ActivePlayersModal';
import AdminBalanceModal from '../components/Modal/AdminBalanceModal';
import DateRangeModal from '../components/Modal/DateRangeModal';
import { fetchTransactionSummary } from '../actions/dashboard';
import TopUpLCModal from '../components/Wallet/TopUpLCModal';
import { useNavigate } from 'react-router-dom';
import Wallet from '../components/Wallet/Wallet';

const HomePage = () => {
  const {
    selectedDashboardType,
    dashboardTypes,
    filterTypes,
    trsPageData,
    seasonOverview,
    summary,
    graphData,
    adminBalance,
    usdPrice,
  } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [modalSection, setModalSection] = useState('');
  const navigate = useNavigate();

  const changeFilter = (filter) => {
    if (filter === 'Range') setModalSection('range_filter');
    else dispatch(setTrsPageData({ date_type: filter }));
  };

  useEffect(() => {
    dispatch(fetchTransactionSummary(trsPageData));
  }, [dispatch, trsPageData, selectedDashboardType]);

  return (
    <>
      {modalSection === 'active_players' ? (
        <ActivePlayersModal closeModal={setModalSection} />
      ) : modalSection === 'admin_balance' ? (
        <AdminBalanceModal closeModal={setModalSection} />
      ) : modalSection === 'send_cku' ? (
        <Wallet closeModal={setModalSection} />
      ) : modalSection === 'range_filter' ? (
        <DateRangeModal
          closeModal={setModalSection}
          selector="dashboard"
          stateName="trsPageData"
          additionalState={{ date_type: 'Range' }}
          setter={setTrsPageData}
        />
      ) : modalSection === 'topup_lc' ? (
        <TopUpLCModal
          closeModal={setModalSection}
          walletAddress={adminBalance.luckycat88_wallet_address}
        />
      ) : null}
      <LayoutAdmin pageSubtitle="Dashboard" pageTitle="Dashboard">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="w-full lg:w-[60%] bg-white-f p-[24px] lg:mb-[32px]">
            <h3 className="font-poppins-semibold text-base">Growth</h3>
            <div className="flex flex-row my-[8px]">
              {dashboardTypes.map((type) => (
                <button
                  type="button"
                  key={type}
                  onClick={() =>
                    dispatch(setTrsPageData({ growth_type: type }))
                  }
                  className={`min-w-[105px] h-[32px] text-center font-poppins-semibold tracking-[0.3px] border border-black-100 rounded-lg mr-[8px] ${
                    trsPageData.growth_type === type
                      ? 'bg-black-100 text-white'
                      : 'bg-white'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
            <div className="flex flex-row overflow-auto lg:overflow-hidden w-full max-w-none">
              {filterTypes.map((filter) => (
                <button
                  type="button"
                  key={filter}
                  onClick={() => changeFilter(filter)}
                  className={`min-w-[105px] h-[32px] text-center font-poppins-semibold tracking-[0.3px] border border-black-100 rounded-lg mr-[8px] ${
                    trsPageData.date_type === filter
                      ? 'bg-black-100 text-white'
                      : 'bg-white'
                  }`}
                >
                  {filter}
                </button>
              ))}
            </div>
            {graphData.length ? (
              <div className="w-full h-[250px] mt-[30px] lg:p-[18px]">
                <DashboardCharts />
              </div>
            ) : null}
            <div className="flex flex-col text-sm mt-[32px]">
              <h3 className="font-poppins-semibold mb-[8px] text-base">
                Summary
              </h3>
              {summary ? (
                <div>
                  <div className="flex flex-row w-full mt-[4px]">
                    <label className="w-[55%] lg:w-[30%]">Total Players</label>
                    <p>{thousandSeparator(summary.total_players)}</p>
                  </div>
                  <div className="flex flex-row w-full items-center mt-[4px]">
                    <label className="w-[55%] lg:w-[30%] flex flex-row">
                      Total Active Players{' '}
                      <button
                        className="flex"
                        onClick={() => setModalSection('active_players')}
                      >
                        <ion-icon
                          name="information-circle-outline"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginLeft: '8px',
                          }}
                        ></ion-icon>
                      </button>
                    </label>
                    <p>{thousandSeparator(summary.total_active_players)}</p>
                  </div>
                  <div className="flex flex-row w-full mt-[4px]">
                    <label className="w-[55%] lg:w-[30%]">
                      Total Tickets Sold
                    </label>
                    <p>{thousandSeparator(summary.total_tickets_sold)}</p>
                  </div>
                  <div className="flex flex-row w-full mt-[4px]">
                    <label className="w-[55%] lg:w-[30%]">
                      Total Transaction (CKU)
                    </label>
                    <p>{thousandSeparator(summary.total_lucky_coin)}</p>
                  </div>
                  <div className="flex flex-row w-full mt-[4px]">
                    <label className="w-[55%] lg:w-[30%]">Total Winners</label>
                    <p>{thousandSeparator(summary.total_winners)}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col mt-[16px] lg:mt-0 w-full lg:w-[35%] lg:mr-[16px]">
            <div className="p-[24px] bg-white-f">
              <h3 className="text-base font-poppins-semibold mb-[8px]">
                LuckyCat's Balance
              </h3>
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <h3 className="font-poppins-semibold text-base">
                    Admin's Balance
                  </h3>
                  {/* <button
                    className="flex"
                    type="button"
                    onClick={() => setModalSection('admin_balance')}
                  >
                    <ion-icon
                      name="information-circle-outline"
                      style={{
                        width: '16px',
                        height: '16px',
                        marginLeft: '8px',
                      }}
                    ></ion-icon>
                  </button> */}
                </div>
                <div className="flex flex-row items-center">
                  <img
                    src={CKUIcon}
                    alt="cku-icon"
                    className="w-[22px] h-[22px]"
                  />
                  <p className="font-poppins-semibold ml-[4px]">
                    {adminBalance
                      ? thousandSeparator(adminBalance.lucky_coin_balance)
                      : '-'}
                  </p>
                </div>
                {/* <p className="text-sm">
                  USD{' '}
                  {adminBalance
                    ? thousandSeparator(
                        adminBalance.lucky_coin_balance * usdPrice
                      )
                    : '-'}
                </p> */}
              </div>
              <div className="flex flex-row items-center justify-between mt-[8px]">
                <button
                  type="button"
                  onClick={() => setModalSection('send_cku')}
                  className="w-[48%] h-fit font-poppins-semibold bg-white px-[16px] py-[4px] border border-black-100 rounded-[20px]"
                >
                  Send CKU
                </button>
                <button
                  type="button"
                  onClick={() => setModalSection('send_cku')}
                  className="w-[48%] h-fit font-poppins-semibold bg-white px-[16px] py-[4px] border border-black-100 rounded-[20px]"
                >
                  Top Up CKU
                </button>
              </div>
            </div>
            {seasonOverview ? (
              <div className="p-[24px] mt-[16px] bg-white-f">
                <h3 className="font-poppins-semibold text-base">
                  Season Overview
                </h3>
                <div className="flex flex-row w-full my-[8px]">
                  <label className="w-[50%]">Active Seasons</label>
                  <p
                    className="underline cursor-pointer"
                    onClick={() => navigate(`/lottery`)}
                  >
                    {thousandSeparator(seasonOverview.active_seasons)}
                  </p>
                </div>
                <div className="flex flex-row w-full">
                  <label className="w-[50%]">Past Seasons</label>
                  <p
                    className="underline cursor-pointer"
                    onClick={() => navigate(`/lottery`)}
                  >
                    {thousandSeparator(seasonOverview.past_seasons)}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </LayoutAdmin>
    </>
  );
};

export default HomePage;
