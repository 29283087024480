import { useForm, Controller } from 'react-hook-form';
import Modal from '../Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { addFreezeDate } from '../../utils/validationForm';
import DatePickerInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { checkSameDate } from '../../utils/date';
import DateConfirmation from './DateConfirmation';
import moment from 'moment';

const FreezeDateModal = ({ closeModal }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(addFreezeDate),
  });
  const { seasonID, selectedDrawdownID, selectedDrawdownInfo } = useSelector(
    (state) => state.drawdown
  );
  const { selectedSeasonInfo } = useSelector((state) => state.season);
  const [minTime, setMinTime] = useState(
    new Date().getHours() > 17
      ? moment().set({ hour: 0, minute: 0 }).toDate()
      : new Date()
  );
  const [confirmModal, setConfirmModal] = useState(false);

  const onSubmit = async (data) => {
    setConfirmModal(data);
  };

  const setDefaultValue = () => {
    const date = moment().isBefore(selectedSeasonInfo.startDate)
      ? new Date(selectedSeasonInfo.startDate)
      : new Date();

    return date.getHours() > 17
      ? moment(date)
          .set({ hour: 17, minute: 0, second: 0 })
          .add(1, 'day')
          .toDate()
      : moment(date).set({ hour: 17, minute: 0, second: 0 }).toDate();
  };

  useEffect(() => {
    if (moment().isBefore(selectedSeasonInfo.startDate)) {
      setMinTime(moment().set({ hour: 0, minute: 0 }).toDate());
    }
  }, [selectedSeasonInfo]);

  useEffect(() => {
    if (confirmModal === 1) closeModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmModal]);

  useEffect(() => {
    const data = {
      lottery_season_id: seasonID ? seasonID : '',
      drawdown_id: selectedDrawdownID ? selectedDrawdownID : '',
      purchase_freeze_time: setDefaultValue(),
    };
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonID, selectedDrawdownID, selectedDrawdownInfo, reset]);

  return (
    <>
      {confirmModal ? (
        <DateConfirmation
          closeModal={setConfirmModal}
          payload={confirmModal}
          type="purchase_freeze_time"
        />
      ) : null}
      <Modal title="Purchase Freeze Time" closeModal={closeModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col mb-[18px]">
            <label className="text-sm">
              Purchase Freeze Time (Before Draw End)
            </label>
            <div className="customDatePicker">
              <Controller
                control={control}
                name="purchase_freeze_time"
                render={({ field }) => (
                  <DatePickerInput
                    placeholderText="Select date and time"
                    onChange={(date) => {
                      let formattedDate = new Date(date),
                        currentHour = new Date().getHours(),
                        currentMinutes =
                          new Date().getMinutes() < 30
                            ? 30
                            : new Date().getMinutes();

                      if (checkSameDate(formattedDate, new Date())) {
                        setMinTime(new Date());
                        if (formattedDate.getHours() < currentHour) {
                          formattedDate = moment(formattedDate)
                            .set({
                              hour:
                                currentMinutes > 30
                                  ? currentHour + 1
                                  : currentHour,
                              minute: currentMinutes > 30 ? 0 : 30,
                            })
                            .toDate();
                        }
                      } else {
                        setMinTime(new Date().setHours(0, 0));
                      }

                      formattedDate.setSeconds(0);
                      field.onChange(formattedDate);
                    }}
                    selected={field.value ? field.value : setDefaultValue()}
                    dateFormat="MM/dd/yyyy, HH:mm"
                    minDate={
                      moment().isBefore(selectedSeasonInfo.startDate)
                        ? new Date(selectedSeasonInfo.startDate).setHours(0, 0)
                        : new Date()
                    }
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    isClearable
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    minTime={minTime}
                    maxTime={new Date().setHours(23, 59)}
                    autoComplete="off"
                    className={clsx(
                      'w-full bg-white text-sm block px-[8px] py-[11px] border border-black-100 rounded-lg outline-0'
                    )}
                  />
                )}
              />
            </div>
          </div>
          <p className="text-sm">
            <span className="font-poppins-semibold">Note: </span>Once the freeze
            date is set, players will no longer be able to buy any tickets for
            the current active draw season.
          </p>
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              onClick={() => reset()}
              type="button"
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              Discard All
            </button>
            <button
              type="submit"
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default FreezeDateModal;
