import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { formatNumberWithSuffix, thousandSeparator } from '../../utils/math';
import { useSelector } from 'react-redux';
import moment from 'moment';

const DashboardCharts = () => {
  const { graphData, trsPageData } = useSelector((state) => state.dashboard);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const payloadExt = payload[0].payload;
      return (
        <div className="bg-white-f p-[8px] rounded-lg shadow-tooltip">
          <p className="label">
            {moment(payloadExt.date).format('D MMMM YYYY')}
          </p>
          {trsPageData.growth_type === 'Transaction' ? (
            <p className="font-poppins">{`CKU ${thousandSeparator(
              payloadExt.total_lucky_coin
            )}`}</p>
          ) : null}
          <p className="font-poppins">
            {trsPageData.growth_type === 'Player'
              ? `${thousandSeparator(
                  payloadExt.total_active_players
                )} New Users`
              : `${thousandSeparator(
                  payloadExt.total_tickets_sold
                )} Tickets Sold`}
          </p>
        </div>
      );
    }
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={250}
      className="text-sm font-poppins-semibold"
    >
      <LineChart data={graphData}>
        <CartesianGrid verticalPoints={[1]} vertical={false} />
        <XAxis
          tickSize={10}
          dataKey={trsPageData.date_type === 'Today' ? 'hour' : 'date_format'}
          tickMargin={10}
          axisLine={false}
          padding={{ left: 30, right: 30 }}
        />
        <YAxis
          axisLine={false}
          tickMargin={15}
          tickLine={false}
          tickFormatter={
            trsPageData.growth_type === 'Player'
              ? thousandSeparator
              : formatNumberWithSuffix
          }
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="linear"
          dataKey={
            trsPageData.growth_type === 'Player'
              ? 'total_active_players'
              : 'total_lucky_coin'
          }
          stroke={trsPageData.growth_type === 'Player' ? '#ffaa33' : '#2F80ED'}
          strokeWidth={3}
          dot={false}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashboardCharts;
