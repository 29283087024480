import { thousandSeparator } from '../../utils/math';
import CoinLogo from '../../assets/imgs/coin.png';
import { useSelector } from 'react-redux';

const LCAmount = ({ showNote = false, section }) => {
  const { adminBalance } = useSelector((state) => state.dashboard);
  return (
    <div
      className={`p-[8px] flex flex-col items-center justify-center w-full my-[16px] ${
        section === 'Get CKU' && '!p-0 !m-0 border-0'
      }`}
    >
      <h3 className="font-poppins-semibold text-base">
        Admin's Current CKU Balance
      </h3>
      <div className="flex flex-row items-center my-[2px]">
        <img
          src={CoinLogo}
          alt="coin logo"
          style={{ width: '22px', height: '22px' }}
        />
        <p className="text-sm ml-[4px] font-poppins-semibold">
          {thousandSeparator(
            adminBalance && adminBalance.lucky_coin_balance
              ? adminBalance.lucky_coin_balance
              : 0
          )}
        </p>
      </div>
      {showNote ? (
        <p className="text-sm opacity-50 mt-[4px]">
          <span className="font-poppins-semibold">Note: </span>
          Your CKU coin is automatically converted into CKU for your convenience
          during the withdrawal process.
        </p>
      ) : null}
    </div>
  );
};

export default LCAmount;
