import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import SelectPrice from './SelectPrize';

const PrizeDistribution = ({ watch, register, setValue }) => {
  const [firstPrize, secondPrize, thirdPrize] = watch(
    ['first_prize', 'second_prize', 'third_prize'],
    { first_prize: 0, second_prize: 0, third_prize: 0 }
  );
  const prizes = ['first_prize', 'second_prize', 'third_prize'];
  const [showPrizeSelect, setShowPrizeSelect] = useState('');

  const handleChangePrize = (ev, type) => {
    const value = ev.target.value;
    setValue(type, value);
    if (type === 'first_prize') {
      setValue('second_prize', '');
      setValue('third_prize', '');
    } else if (type === 'second_prize') {
      setValue('third_prize', '');
    }
    setShowPrizeSelect('');
  };

  const openOptions = (ops) => {
    showPrizeSelect === ops ? setShowPrizeSelect('') : setShowPrizeSelect(ops);
  };
  return (
    <div className="flex flex-row w-full justify-between gap-[8px]">
      {prizes.map((prize) => (
        <div className="flex flex-col text-sm mb-[18px] w-[32%]" key={prize}>
          <label className="capitalize">{prize.split('_').join(' ')}</label>
          <div className="relative">
            <input
              type="number"
              {...register(prize, {
                onBlur: (e) => {
                  const val = e.target.value;
                  if (val.length > 2) {
                    setValue(prize, +val.substring(0, 2));
                  }
                  if (prize === 'first_prize') {
                    setValue('second_prize', '');
                    setValue('third_prize', '');
                  } else if (prize === 'second_prize') {
                    setValue('third_prize', '');
                  }
                },
              })}
              placeholder="Select %"
              disabled={
                (prize === 'second_prize' && !firstPrize) ||
                (prize === 'third_prize' && !secondPrize)
              }
              className="w-full text-sm py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
            />
            <SelectPrice
              setter={setShowPrizeSelect}
              prizeNum={prize}
              handleChange={handleChangePrize}
              selected={showPrizeSelect}
              maxPercentage={
                prize === 'second_prize' && firstPrize
                  ? 95 - firstPrize
                  : prize === 'third_prize' && secondPrize
                  ? 100 - firstPrize - secondPrize
                  : null
              }
            />
            <button
              className="absolute right-[8px] top-[12px] flex"
              type="button"
              onClick={() => {
                if (
                  (prize === 'second_prize' && !firstPrize) ||
                  (prize === 'third_prize' && !secondPrize)
                ) {
                  enqueueSnackbar(
                    `${
                      !firstPrize
                        ? 'First prize'
                        : !secondPrize
                        ? 'Second prize'
                        : ''
                    } should be filled first`,
                    { variant: 'warning' }
                  );
                } else {
                  openOptions(prize);
                }
              }}
            >
              <ion-icon name="caret-down-outline"></ion-icon>
            </button>
            {prize === 'first_prize' && firstPrize ? (
              <p className="absolute left-[24px] top-[12px]">%</p>
            ) : prize === 'second_prize' && secondPrize ? (
              <p className="absolute left-[24px] top-[12px]">%</p>
            ) : prize === 'third_prize' && thirdPrize ? (
              <p className="absolute left-[24px] top-[12px]">%</p>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PrizeDistribution;
