const getPageTitle = (path) => {
  if (path.includes('admins') || path.includes('admin')) return 'Admins';
  else if (path.includes('lottery')) return 'Lottery Setting';
  else if (path.includes('tickets') || path.includes('ticket'))
    return 'Tickets';
  else if (path.includes('recruiters') || path.includes('recruiter'))
    return 'Recruiters';
  else if (path.includes('players')) return 'Players';
  else if (path.includes('content')) return 'Pages Content';
  else if (path.includes('contact')) return 'Contact';
  else if (path.includes('payout')) return 'Cash Winner';
  else return 'Dashboard';
};

const getPathName = (page) => {
  page = page.toLowerCase();
  if (page.includes('admin')) return '/admins';
  else if (page.includes('season')) return '/lottery';
  else if (page.includes('tickets') || page.includes('ticket'))
    return '/tickets';
  else if (page.includes('player')) return '/players';
  else if (page.includes('recruiters')) return '/recruiters';
  else if (page.includes('payout')) return '/payout';
  else return 'Dashboard';
};

export { getPageTitle, getPathName };
