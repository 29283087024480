import { useForm, Controller } from 'react-hook-form';
import Modal from '../Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDrawDate } from '../../utils/validationForm';
import DatePickerInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { checkSameDate } from '../../utils/date';
import DateConfirmation from './DateConfirmation';
import moment from 'moment';

const DrawDateModal = ({ closeModal }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(addDrawDate),
  });
  const selectedSeasonInfo = useSelector(
    (state) => state.season.selectedSeasonInfo
  );
  const { seasonID, selectedDrawdownID, selectedDrawdownInfo, drawdownList } =
    useSelector((state) => state.drawdown);
  const [minTime, setMinTime] = useState(new Date());
  const [confirmModal, setConfirmModal] = useState(false);

  const onSubmit = (data) => {
    setConfirmModal(data);
  };

  const getMinDate = () => {
    var date =
      selectedSeasonInfo && selectedDrawdownInfo.purchase_freeze_time
        ? moment(selectedDrawdownInfo.purchase_freeze_time)
            .add({ minute: 30 })
            .toDate()
        : new Date();

    const selectedIdx = drawdownList.findIndex(
      (d) => d._id === selectedDrawdownInfo._id
    );
    const previousDrawdown =
      selectedIdx > 0 ? drawdownList[selectedIdx - 1] : null;

    if (
      previousDrawdown &&
      previousDrawdown.draw_date &&
      moment(previousDrawdown.draw_date).isAfter(
        selectedDrawdownInfo.purchase_freeze_time
      )
    ) {
      date = moment(previousDrawdown.draw_date).add({ minutes: 30 }).toDate();
    }

    return date;
  };

  useEffect(() => {
    setMinTime(getMinDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (confirmModal === 1) closeModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmModal]);

  useEffect(() => {
    const data = {
      lottery_season_id: seasonID ? seasonID : '',
      drawdown_id: selectedDrawdownID ? selectedDrawdownID : '',
      draw_date:
        selectedDrawdownInfo && selectedDrawdownInfo.draw_date
          ? new Date(selectedDrawdownInfo.draw_date)
          : getMinDate(),
    };
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonID, selectedDrawdownID, selectedDrawdownInfo, reset]);

  return (
    <>
      {confirmModal ? (
        <DateConfirmation
          closeModal={setConfirmModal}
          payload={confirmModal}
          type="draw_date"
        />
      ) : null}
      <Modal title="Draw Date" closeModal={closeModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col mb-[18px]">
            <label className="text-sm">Draw Date</label>
            <div className="customDatePicker">
              <Controller
                control={control}
                name="draw_date"
                render={({ field }) => (
                  <DatePickerInput
                    placeholderText="Select date"
                    onChange={(date) => {
                      const formattedDate = new Date(date);
                      if (
                        selectedSeasonInfo &&
                        selectedDrawdownInfo.purchase_freeze_time
                      ) {
                        const freezeDate = getMinDate(),
                          freezeHour = freezeDate.getHours(),
                          freezeMinutes = freezeDate.getMinutes();

                        if (checkSameDate(formattedDate, freezeDate)) {
                          if (formattedDate.getHours() < freezeHour) {
                            formattedDate.setHours(freezeHour, freezeMinutes);
                          }
                          setMinTime(freezeDate);
                        } else {
                          setMinTime(new Date().setHours(0, 0));
                        }
                      }
                      formattedDate.setSeconds(0);
                      field.onChange(formattedDate);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    selected={field.value ? field.value : getMinDate()}
                    dateFormat="MM/dd/yyyy, HH:mm"
                    minDate={getMinDate()}
                    isClearable
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    minTime={minTime}
                    maxTime={new Date().setHours(23, 59)}
                    autoComplete="off"
                    className={clsx(
                      'w-full bg-white text-sm block px-[8px] py-[11px] border border-black-100 rounded-lg outline-0'
                    )}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              onClick={() => reset()}
              type="button"
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              Discard All
            </button>
            <button
              type="submit"
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default DrawDateModal;
