import { createSlice } from '@reduxjs/toolkit';
import { fetchPayoutList, updatePaymentProof } from '../actions/payout';

const initialState = {
  payoutList: [],
  totalPages: 0,
  pageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  selectedPayout: null,
  isLoading: false,
};

export const payout = createSlice({
  name: 'payout',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
    setSelectedPayout: (state, action) => {
      state.selectedPayout = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayoutList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPayoutList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.payoutList = payload.cash_payout_list;
        state.totalPages = payload.pages;
      })
      .addCase(fetchPayoutList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePaymentProof.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePaymentProof.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const newPayout = payload.transaction;
        const list = state.payoutList;
        const idx = list.findIndex(
          (payout) => payout.transaction_id === newPayout.transaction_id
        );
        list[idx] = newPayout;
        state.payoutList = [...list];
      })
      .addCase(updatePaymentProof.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setIsLoading, setPageData, setSelectedPayout } = payout.actions;

export default payout.reducer;
