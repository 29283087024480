import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import jwtDecode from 'jwt-decode';
import { enqueueSnackbar } from 'notistack';

export const adminLogin = createAsyncThunk(
  'auth/login',
  async (loginData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post('adminLogin', loginData);
      localStorage.setItem('USER_KEY', data.token);
      var decodeToken = jwtDecode(data.token);
      decodeToken = JSON.parse(JSON.stringify(decodeToken));
      return decodeToken;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPasswordStep1 = createAsyncThunk(
  'auth/forgotPasswordStep1',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('adminForgetPasswordStep1', email);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPasswordStep2 = createAsyncThunk(
  'auth/forgotPasswordStep2',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'adminForgetPasswordStep2',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPasswordStep3 = createAsyncThunk(
  'auth/forgotPasswordStep3',
  async (newPassword, { rejectWithValue }) => {
    try {
      const payload = { ...newPassword };
      console.log(payload, '===payload');
      delete payload.token;
      console.log(payload, '===payload');

      const { data } = await serverAPI.post(
        'adminForgetPasswordStep3',
        payload,
        {
          headers: {
            Authorization: `Bearer ${newPassword.token}`,
          },
        }
      );
      const { token } = data;
      if (token) {
        var decodeToken = jwtDecode(token);
        decodeToken = JSON.parse(JSON.stringify(decodeToken));
        console.log(decodeToken, '===token');
        const user = {
          name: decodeToken.name,
          role: decodeToken.role,
          _id: decodeToken._id,
          has_temporary_password: decodeToken.has_temporary_password,
        };
        localStorage.setItem('USER_KEY', token);
        data.user = user;
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
