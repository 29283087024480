import Modal from '../Modal/Modal';

const RewardInfoModal = ({ closeModal }) => {
  return (
    <Modal title="Reward Information" closeModal={closeModal}>
      <div>
        <ul className="text-sm ml-[20px] list-decimal">
          <li>
            <span className="font-poppins-bold">Note: </span> 70% of ticket
            price goes into the pot.
          </li>
          <li>
            <span className="font-poppins-bold">Set a prize split: </span>
            The CKU reward can automatically be added to each prize, first,
            second and third, based on the split from the ticket sales, e.g 70%
            for first prize, 20% for second prize, 10% for third prize.
            <ul className="list-[lower-alpha] ml-[20px]">
              <li>Admin can pause to lock the win split amount.</li>
              <li>
                hen paused the collected CKU will go to the company’s wallet.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-poppins-bold">Purchase Freeze Time: </span>
            Player will not be allowed to purchase the ticket view hours before
            season ended.
          </li>
          <li>
            <span className="font-poppins-bold">Winning Rules</span>
            <ul className="list-[lower-alpha] ml-[20px]">
              <li>
                During the lottery draw down 7 numbers will be called. The 7th
                number is called the “extra number”.
              </li>
              <li>
                After all 7 numbers are called they will put the numbers from
                smallest to biggest, except the extra number. We will get the
                draw numbers from the Hong Kong Mark 6 Lottery to ensure that we
                aren’t able to tamper with the results.
                <ul className="list-[lower-roman] ml-[20px]">
                  <li>
                    1st prize: All 6 drawn numbers match. First 6 numbers not
                    including the extra number.
                  </li>
                  <li>
                    2nd prize: 5 out of 6 drawn numbers plus the extra number
                    match
                  </li>
                  <li>
                    3rd prize: 5 out of 6 drawn numbers match For every ticket
                    you have one chance to win one prize.
                  </li>
                  <li>
                    If you have 8 numbers, that’s the equivalent of 28 tickets.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default RewardInfoModal;
