import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const usePrizeCurrency = (type) => {
  const { selectedDrawdownInfo } = useSelector((state) => state.drawdown);
  const { selectedSeasonInfo } = useSelector((state) => state.season);
  const { selectedTicketInfo } = useSelector((state) => state.ticket);
  const [currency, setCurrency] = useState('CKU');

  useEffect(() => {
    const selected =
      type === 'season'
        ? selectedSeasonInfo
        : type === 'drawdown'
        ? selectedDrawdownInfo
        : type === 'ticket'
        ? selectedTicketInfo
        : null;
    if (selected) {
      const { lottery_season } = selected;
      if (lottery_season && lottery_season.is_cash_payout) setCurrency('Rp');
    }
  }, [selectedDrawdownInfo, selectedSeasonInfo, selectedTicketInfo, type]);

  return currency;
};
