import { useDispatch, useSelector } from 'react-redux';
import { resetPageData, setTicketPageData } from '../../slices/playersSlice';
import StyledTable from '../Table/StyledTable';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTableCell from '../Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../Table/Pagination';
import WinningNumbers from '../Season/WinningNumbers';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DateRangeModal from '../Modal/DateRangeModal';
import StatusFilterModal from '../Modal/StatusFilterModal';
import DataLimit from '../Table/DataLimit';
// import SeasonFilterModal from './SeasonFilterModal';

const PlayerTicketHistory = () => {
  const { ticketPageData, selectedPlayerTicketHistory, ticketPages } =
    useSelector((state) => state.players);
  const dispatch = useDispatch();
  const [modalSection, setModalSection] = useState('');

  const onSearchTicket = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== ticketPageData.search) {
      const search = ev.target.value;
      dispatch(setTicketPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== ticketPageData.page) {
      dispatch(setTicketPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetPageData('ticketPageData'));
    };
  }, [dispatch]);

  return (
    <>
      {modalSection === 'date_range' ? (
        <DateRangeModal
          selector="players"
          stateName="ticketPageData"
          setter={setTicketPageData}
          closeModal={setModalSection}
        />
      ) : modalSection === 'status_filter' ? (
        <StatusFilterModal
          closeModal={setModalSection}
          setter={setTicketPageData}
          fieldName="ticket_status"
          stateName="ticketPageData"
          selector="players"
        />
      ) : null}
      <div className="flex flex-col">
        <h3 className="text-base font-poppins-semibold">Ticket History</h3>
        <div className="flex flex-col my-[16px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Ticket Number, Lottery Number or Status"
            onKeyDown={onSearchTicket}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <div className="flex flex-row gap-[8px] overflow-auto max-w-none w-full mb-[8px] pb-[8px]">
          <button
            type="button"
            onClick={() => setModalSection('status_filter')}
            className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
          >
            Status Filter
          </button>
          <button
            type="button"
            onClick={() => setModalSection('date_range')}
            className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
          >
            Date Filter
          </button>
        </div>
        {selectedPlayerTicketHistory && selectedPlayerTicketHistory.length ? (
          <div>
            <DataLimit
              initialLimit={ticketPageData.dataLimit}
              setter={setTicketPageData}
              classList="mb-[16px]"
            />
            <StyledTableContainer>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Ticket ID</StyledTableCell>
                    <StyledTableCell>Ticket Status</StyledTableCell>
                    <StyledTableCell>Season</StyledTableCell>
                    <StyledTableCell>Draw ID</StyledTableCell>
                    <StyledTableCell>Lottery Number</StyledTableCell>
                    <StyledTableCell>Player ID</StyledTableCell>
                    <StyledTableCell>Purchase Date</StyledTableCell>
                    <StyledTableCell>Prize</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlayerTicketHistory.map((ticket) => (
                    <TableRow key={ticket._id}>
                      <StyledTableCell>{ticket.ticket_id}</StyledTableCell>
                      <StyledTableCell>{ticket.ticket_status}</StyledTableCell>
                      <StyledTableCell>
                        {ticket.lottery_season.name}
                      </StyledTableCell>
                      <StyledTableCell>{ticket.drawdown.id}</StyledTableCell>
                      <StyledTableCell>
                        <WinningNumbers
                          numbers={ticket.ticket_number}
                          showCopy={false}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{ticket.player_id}</StyledTableCell>
                      <StyledTableCell>
                        {moment(ticket.createdAt).format(
                          'DD-MM-YYYY, HH:mm:ss'
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ticket.prize
                          ? `${setPrizeCurrency(ticket)} ${thousandSeparator(
                              ticket.prize
                            )}`
                          : '-'}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            <Pagination
              totalPages={ticketPages}
              pageData={ticketPageData}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          <p className="text-sm">No ticket data found!</p>
        )}
      </div>
    </>
  );
};

export default PlayerTicketHistory;
