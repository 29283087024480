import Modal from './Modal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePickerInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { dateRangeFilter } from '../../utils/validationForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const DateRangeModal = ({
  closeModal,
  setter,
  selector,
  stateName,
  additionalState,
}) => {
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(dateRangeFilter),
  });
  const dateFilter = useSelector((state) => state[selector][stateName]);
  const dispatch = useDispatch();
  const initialValue = {
    startDate: '',
    endDate: '',
  };
  const watchStartDate = watch('startDate', new Date());

  useEffect(() => {
    if (dateFilter && (dateFilter.startDate || dateFilter.endDate)) {
      const formattedFilter = {
        startDate: new Date(dateFilter.startDate),
        endDate: new Date(dateFilter.endDate),
      };
      reset(formattedFilter);
    }
  }, [dateFilter, reset]);

  const onSubmit = (data) => {
    let payload = JSON.parse(JSON.stringify(data));
    if (additionalState) payload = { ...payload, ...additionalState };
    dispatch(setter(payload));
    closeModal(false);
  };

  const clearAll = () => {
    dispatch(setter(initialValue));
    closeModal(false);
  };

  return (
    <Modal title="Date Range" closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mb-[18px]">
          <label className="text-sm">Start Date</label>
          <div className="customDatePicker">
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePickerInput
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  autoComplete="off"
                  className={clsx(
                    'w-full bg-white text-sm block px-[8px] py-[11px] border border-black-100 rounded-lg outline-0'
                  )}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col mb-[18px]">
          <label className="text-sm">End Date</label>
          <div className="customDatePicker">
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <DatePickerInput
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  minDate={new Date(watchStartDate)}
                  autoComplete="off"
                  className={clsx(
                    'w-full bg-white text-sm block px-[8px] py-[11px] border border-black-100 rounded-lg outline-0'
                  )}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={clearAll}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Apply
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DateRangeModal;
