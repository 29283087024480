import { useForm } from 'react-hook-form';
import Modal from '../Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { connectWallet } from '../../utils/validationForm';
import ActionButton from '../Button/ActionButton';
import Input from '../Input/Input';
import { useDispatch } from 'react-redux';
import { connectNewWallet, sendConnectWalletOTP } from '../../actions/wallet';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../../utils/toastMessage';

const ConnectWallet = ({ closeModal }) => {
  const { register, handleSubmit, formState, getFieldState, getValues } =
    useForm({
      resolver: yupResolver(connectWallet),
    });
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const done = await dispatch(connectNewWallet(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(true);
      }
    }
  };

  const sendOTP = async () => {
    if (!getFieldState('email').invalid) {
      const done = await dispatch(
        sendConnectWalletOTP({ email: getValues('email') })
      );
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') enqueueSnackbar(message, { variant });
      }
    } else {
      enqueueSnackbar('Please provide a valid email', { variant: 'warning' });
    }
  };

  return (
    <Modal closeModal={closeModal} title="Connect External Wallet">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row items-center w-full">
          <Input
            register={register}
            name="email"
            title="Your Latoken Email"
            placeholder="yourmail@mail.com"
            errors={formState.errors}
            classes="!w-[59%] !sm:w-[66%]"
          />
          <ActionButton
            title="Send OTP"
            type="button"
            classes="!w-fit ml-[10px] mr-[4px] text-sm !px-[12px] !py-[4px]"
            onClick={() => sendOTP()}
          />
        </div>
        <Input
          register={register}
          name="otp"
          title="OTP Number"
          placeholder="Input OTP sent to your Latoken email"
          errors={formState.errors}
        />
        <hr className="h-px my-[16px] bg-[#E5E5E5] border-0"></hr>
        <div className="flex flex-col">
          <Input
            register={register}
            name="wallet_name"
            title="Wallet Name"
            placeholder="Wallet #1"
            errors={formState.errors}
          />
          <p className="text-xs mt-[-18px]">
            All LC88 players will be able to see this wallet name.
          </p>
        </div>
        <div className="p-[10px] flex flex-col border border-black-100 my-[16px] bg-light-yellow">
          <p className="font-poppins-semibold text-sm">Note:</p>
          <p className="text-sm">
            Please ensure your Latoken account is active. Blockchain we use is{' '}
            <span className="font-poppins-semibold">ERC20</span>.
          </p>
        </div>
        <ActionButton type="submit" title="Save" />
      </form>
    </Modal>
  );
};

export default ConnectWallet;
