import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { setUserInfo } from '../slices/authSlice';
import { useNavigate } from 'react-router-dom';

export const useIsLoggedIn = () => {
  const user = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const token = localStorage.getItem('USER_KEY');

  if (user && user.name && token) return true;
  else if ((!user || !user.name) && token) {
    var decodeToken = jwtDecode(token);
    decodeToken = JSON.parse(JSON.stringify(decodeToken));
    // console.log(decodeToken, '===token');
    dispatch(
      setUserInfo({
        name: decodeToken.name,
        role: decodeToken.role,
        _id: decodeToken._id,
        has_temporary_password: decodeToken.has_temporary_password,
      })
    );
    return true;
  }
  return false;
};

export const useIsSuperAdmin = () => {
  const user = useSelector((state) => state.auth.userInfo);
  return user.role === 'Super Admin' ? true : false;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.removeItem('USER_KEY');
  dispatch(setUserInfo(null));
  navigate('/login');
};
