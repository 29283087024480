import { createSlice } from '@reduxjs/toolkit';
import {
  fetchTransactionSummary,
  getAdminBalance,
  getUSDPrice,
} from '../actions/dashboard';
import moment from 'moment/moment';

const initialState = {
  dashboardTypes: ['Transaction', 'Player'],
  filterTypes: ['All', 'Today', 'This Week', 'This Month', 'Range'],
  graphData: [],
  adminBalance: null,
  summary: [],
  seasonOverview: [],
  isLoading: false,
  trsPageData: {
    growth_type: 'Transaction',
    date_type: 'This Week',
    startDate: null,
    endDate: null,
  },
  usdPrice: 0,
};

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTrsPageData: (state, action) => {
      state.trsPageData = { ...state.trsPageData, ...action.payload };
    },
    setAdminBalance: (state, action) => {
      state.adminBalance = action.payload;
    },
  },
  extraReducers: {
    [fetchTransactionSummary.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTransactionSummary.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      let { graph_data, summary, admin_balance, season_overview } = payload;
      graph_data.forEach((data) => {
        if (!data.total_active_players) data.total_active_players = 0;
        if (!data.total_lucky_coin) data.total_lucky_coin = 0;

        if (data.date) {
          data.date_format = moment(data.date).format('MMM D');
        } else if (data.hour !== undefined) {
          data.hour < 12
            ? (data.hour = `${data.hour} AM`)
            : (data.hour = `${data.hour} PM`);
        }
      });
      state.graphData = graph_data;
      state.summary = summary;
      state.adminBalance = admin_balance;
      state.seasonOverview = season_overview;
    },
    [fetchTransactionSummary.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAdminBalance.pending]: (state) => {
      state.isLoading = true;
    },
    [getAdminBalance.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.adminBalance = payload.admin_balance;
    },
    [getAdminBalance.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getUSDPrice.fulfilled]: (state, { payload }) => {
      state.usdPrice = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading, setTrsPageData, setAdminBalance } =
  dashboard.actions;

export default dashboard.reducer;
