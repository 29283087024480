import Modal from '../Modal/Modal';
import WinningNumbers from './WinningNumbers';
import { addWinningNumbers } from '../../actions/drawdown';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

const WinNumbersConfirmModal = ({ closeModal, payload }) => {
  const dispatch = useDispatch();
  const submitWinNumbers = async () => {
    const done = await dispatch(addWinningNumbers(payload));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        closeModal(1);
        enqueueSnackbar(message, { variant });
      }
    }
  };
  return (
    <Modal closeModal={closeModal} title="Win Numbers Confirmation!">
      <h3 className="text-base font-poppins-semibold mb-[8px] text-red">
        Confirmation!
      </h3>
      <p className="text-sm">
        You can’t undo this action, are you sure you the win numbers are
        correct?
      </p>
      <div className="flex flex-col justify-center items-center mt-[12px]">
        <WinningNumbers
          numbers={payload.number_list}
          extra={payload.extra_number}
          showCopy={false}
        />
      </div>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal()}
          className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
        >
          Back
        </button>
        <button
          type="button"
          onClick={submitWinNumbers}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default WinNumbersConfirmModal;
