import moment from 'moment';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';
import {
  createUpdateDrawdate,
  createUpdateFreezeDate,
} from '../../actions/drawdown';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';

const DateConfirmation = ({ payload, type, closeModal }) => {
  const dispatch = useDispatch();

  const submitDate = async () => {
    const done =
      type === 'draw_date'
        ? await dispatch(createUpdateDrawdate(payload))
        : await dispatch(createUpdateFreezeDate(payload));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(1);
      }
    }
  };

  return (
    <Modal closeModal={closeModal} title="Date Confirmation">
      <h3 className="text-base font-poppins-semibold mb-[8px] text-red">
        Confirmation!
      </h3>
      <p className="text-sm">
        You can’t undo this action, are you sure that the date is correct?
      </p>
      <h3 className="text-center text-xxl my-[10px] font-poppins-semibold">
        {type === 'draw_date'
          ? moment(payload.draw_date).format('DD-MM-YYYY, HH:mm')
          : moment(payload.purchase_freeze_time).format('DD-MM-YYYY, HH:mm')}
      </h3>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal()}
          className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
        >
          Back
        </button>
        <button
          type="button"
          onClick={submitDate}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default DateConfirmation;
