import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobileNavOpen } from '../../slices/navbarSlice';
import Navbar from '../Navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import SeasonSection from '../Season/SeasonSection';
import { getPathName } from '../../utils/pageTitle';
import AdminChangePassword from '../Admin/AdminChangePassword';
import ContentSection from '../Content/ContentSection';
import PlayerDetailSection from '../Player/PlayerDetailSection';

const LayoutAdmin = ({ pageTitle, pageSubtitle, children }) => {
  const isMobileNavOpen = useSelector((state) => state.navbar.isMobileNavOpen);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const openNavbar = () => {
    dispatch(setIsMobileNavOpen(!isMobileNavOpen));
  };
  const [pageJournery, setPageJourney] = useState(null);
  const movePage = (page, idx) => {
    if (idx === 0) {
      navigate(getPathName(page));
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#f7f7f7';
  }, []);

  useEffect(() => {
    const splittedTitle = pageSubtitle.split(', ');
    if (splittedTitle.length > 1) {
      setPageJourney(splittedTitle);
    }
  }, [pageSubtitle]);

  return (
    <>
      <AdminChangePassword />
      <div className="sm:hidden">
        <Navbar />
        <header className="w-full bg-grey-10 flex flex-row items-center justify-between px-[16px] py-[20.5px]">
          <h4 className="text-white font-poppins-semibold text-lg text-left">
            {pageTitle}
          </h4>

          <button
            type="button"
            className="bg-transparent sm:hidden"
            onClick={openNavbar}
          >
            <ion-icon
              name="menu"
              mode="ios"
              style={{ color: 'white', width: '24px', height: '24px' }}
            ></ion-icon>
          </button>
        </header>
        <main className="bg-white h-[100vh]">
          <div className="flex flex-row items-center ml-[16px]">
            {pageJournery && pageJournery.length ? (
              pageJournery.map((page, index) => (
                <div className="flex items-center" key={index}>
                  <button
                    onClick={() => movePage(page, index)}
                    className="font-poppins-semibold py-[24px]"
                  >
                    {page}
                  </button>
                  {index < pageJournery.length - 1 ? (
                    <ion-icon
                      name="caret-forward-outline"
                      style={{ margin: '0 10px' }}
                    ></ion-icon>
                  ) : null}
                </div>
              ))
            ) : (
              <h3 className="font-poppins-semibold text-base py-[24px]">
                {pageSubtitle}
              </h3>
            )}
          </div>
          <SeasonSection />
          <ContentSection />
          <PlayerDetailSection />
          <section
            className={`min-h-[70%] lg:ml-[12px] ${
              location.pathname === '/'
                ? 'bg-white px-[0px] py-[0px]'
                : 'bg-white-f px-[16px] py-[24px] shadow-small'
            }`}
          >
            {children}
          </section>
        </main>
      </div>
      <div className="sm:flex sm:flex-row w-full h-[100vh] hidden">
        <Navbar />
        <div className="flex flex-col w-[100%] ml-[15%]">
          <div className="bg-grey-10 flex flex-row items-center justify-between px-[16px] py-[20.5px]">
            <h4 className="text-white font-poppins-semibold text-lg text-center">
              {pageTitle}
            </h4>
          </div>
          <main className="bg-white h-[100vh]">
            <div className="flex flex-row items-center ml-[16px]">
              {pageJournery && pageJournery.length ? (
                pageJournery.map((page, index) => (
                  <div className="flex items-center" key={index}>
                    <button
                      className="font-poppins-semibold py-[24px]"
                      onClick={() => movePage(page, index)}
                    >
                      {page}
                    </button>
                    {index < pageJournery.length - 1 ? (
                      <ion-icon
                        name="caret-forward-outline"
                        style={{ margin: '0 10px' }}
                      ></ion-icon>
                    ) : null}
                  </div>
                ))
              ) : (
                <h3 className="font-poppins-semibold text-base py-[24px]">
                  {pageSubtitle}
                </h3>
              )}
            </div>
            <SeasonSection />
            <ContentSection />
            <PlayerDetailSection />
            <section
              className={`min-h-[70%] lg:ml-[12px] ${
                location.pathname === '/'
                  ? 'bg-white px-[0px] py-[0px]'
                  : 'bg-white-f px-[16px] py-[24px] shadow-small'
              }`}
            >
              {children}
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default LayoutAdmin;
