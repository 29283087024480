import Modal from '../Modal/Modal';
import AdminCurrentLC from '../Admin/AdminCurrentLC';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newDrawDown } from '../../utils/validationForm';
import Input from '../Input/Input';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDrawdown } from '../../actions/drawdown';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import PrizeDistribution from './PrizeDistribution';
import { useSearchParams } from 'react-router-dom';

const DrawDownModal = ({ closeModal }) => {
  const { register, handleSubmit, reset, formState, watch, setValue } = useForm(
    {
      resolver: yupResolver(newDrawDown),
    }
  );
  const seasonID = useSelector((state) => state.drawdown.seasonID);
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  const onSubmit = async (data) => {
    const done = await dispatch(createDrawdown(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        closeModal(false);
        setSearchParams({
          season_section: 'reward',
          draw_id: 'D1',
        });
        enqueueSnackbar(message, { variant });
      }
    }
  };

  useEffect(() => {
    if (seasonID) setValue('lottery_season_id', seasonID);
  }, [seasonID, setValue]);

  return (
    <Modal closeModal={closeModal} title="Add Draw">
      <AdminCurrentLC type="winning" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          title="Draw Down Name"
          name="drawdown_name"
          register={register}
          placeholder="Name"
          errors={formState.errors}
        />
        <PrizeDistribution
          setValue={setValue}
          register={register}
          watch={watch}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => reset()}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DrawDownModal;
