import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import Loading from '../components/Common/Loading';
import {
  fetchPlayerDetail,
  fetchPlayerTicketHistory,
  fetchPlayerTransactionHistory,
} from '../actions/players';
import PlayerBasicInfo from '../components/Player/PlayerBasicInfo';
import PlayerTrsHistory from '../components/Player/PlayerTrsHistory';
import PlayerTicketHistory from '../components/Player/PlayerTicketHistory';
import { setSelectedSection } from '../slices/playersSlice';

const PlayerDetailPage = () => {
  const {
    isLoading,
    selectedSection,
    detailPageData,
    transactionPageData,
    ticketPageData,
  } = useSelector((state) => state.players);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const playerSection = searchParams.get('player_section');
    if (playerSection) dispatch(setSelectedSection(playerSection));
  }, [dispatch, searchParams]);

  useEffect(() => {
    const params = { ...detailPageData, id };
    if (selectedSection === 'Basic Info') {
      dispatch(fetchPlayerDetail(params));
    }
  }, [dispatch, id, detailPageData, selectedSection]);

  useEffect(() => {
    const params = { ...transactionPageData, id };
    if (selectedSection === 'Transaction History') {
      dispatch(fetchPlayerTransactionHistory(params));
    }
  }, [dispatch, id, transactionPageData, selectedSection]);

  useEffect(() => {
    const params = { ...ticketPageData, id };
    if (selectedSection === 'Ticket History') {
      dispatch(fetchPlayerTicketHistory(params));
    }
  }, [dispatch, id, ticketPageData, selectedSection]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <LayoutAdmin
        pageTitle="Players"
        pageSubtitle="Player List, Player Detail"
      >
        {selectedSection === 'Basic Info' ? (
          <PlayerBasicInfo />
        ) : selectedSection === 'Transaction History' ? (
          <PlayerTrsHistory />
        ) : selectedSection === 'Ticket History' ? (
          <PlayerTicketHistory />
        ) : null}
      </LayoutAdmin>
    </>
  );
};

export default PlayerDetailPage;
