import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import GetLC from './GetLC';
import SendLC from './SendLC';
import WithdrawLC from './WithdrawLC';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Common/Loading';
import SendWithdrawOTP from './SendWithdrawOTP';
import { fetchWalletList } from '../../actions/wallet';
import { resetData, setRefetchWalletList } from '../../slices/walletSlice';
import { useIsSuperAdmin } from '../../hooks/useAuth';
import { enqueueSnackbar } from 'notistack';

const Wallet = ({ closeModal, defaultSection = '', playerEmail = '' }) => {
  const sections = ['Get CKU', 'Send CKU', 'Withdraw'];
  const { isLoading, userInfo } = useSelector((state) => state.auth);
  const isLoadingWallet = useSelector((state) => state.wallet.isLoading);
  const refetchWalletList = useSelector(
    (state) => state.wallet.refetchWalletList
  );
  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const [otpModal, setOtpModal] = useState(false);
  const dispatch = useDispatch();
  const isSuperAdmin = useIsSuperAdmin();

  useEffect(() => {
    if (refetchWalletList) {
      dispatch(fetchWalletList({ id: userInfo._id }));
      dispatch(setRefetchWalletList(false));
    }
    return () => dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchWalletList]);

  useEffect(() => {
    if (otpModal === 1 || !isSuperAdmin) closeModal(false);
    if (!isSuperAdmin) {
      enqueueSnackbar('Only Super Admin can access this feature.', {
        variant: 'warning',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpModal, isSuperAdmin]);

  useEffect(() => {
    if (defaultSection) setSelectedSection(defaultSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSection]);

  return (
    <>
      {isLoading || isLoadingWallet ? <Loading /> : null}
      {isSuperAdmin ? (
        <Modal closeModal={closeModal} title="Wallet">
          {otpModal ? (
            <SendWithdrawOTP section={otpModal} closeModal={setOtpModal} />
          ) : null}
          <div className="flex flex-row w-full mb-[16px] border justify-between border-black-100 rounded-lg">
            {sections.map((section, idx) => (
              <button
                key={`${section}-${idx}`}
                type="button"
                className={`w-[33.3%] font-poppins-medium opacity-50 bg-white py-[10px] text-lg border-r border-r-black-100 last:border-r-0 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg ${
                  selectedSection === section &&
                  '!font-poppins-bold !opacity-100'
                }`}
                onClick={() => setSelectedSection(section)}
              >
                {section}
              </button>
            ))}
          </div>
          {selectedSection === 'Get CKU' ? (
            <GetLC />
          ) : selectedSection === 'Send CKU' ? (
            <SendLC setOtpModal={setOtpModal} playerEmail={playerEmail} />
          ) : selectedSection === 'Withdraw' ? (
            <WithdrawLC setOtpModal={setOtpModal} />
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default Wallet;
