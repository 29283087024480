const SeasonStatus = ({ status }) => {
  let classes =
    'rounded-lg py-[4px] w-full text-sm font-poppins-semibold text-center min-w-[95px] ';
  if (status === 'Hidden') classes += 'bg-[#D9D9D9]';
  else if (status === 'Active') classes += 'bg-[#FFD369]';
  else if (status === 'Finished') classes += 'bg-red text-white';

  return <div className={classes}>{status}</div>;
};

export default SeasonStatus;
