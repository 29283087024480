import { useDispatch, useSelector } from 'react-redux';
import { resetPageData, setTrsPageData } from '../../slices/playersSlice';
import StyledTable from '../Table/StyledTable';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTableCell from '../Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../Table/Pagination';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataLimit from '../Table/DataLimit';
import Wallet from '../Wallet/Wallet';

const PlayerTrsHistory = () => {
  const {
    transactionPageData,
    selectedPlayerTrsHistory,
    transactionPages,
    selectedPlayerDetail,
  } = useSelector((state) => state.players);
  const [sendLCModal, setSendLCModal] = useState(false);
  const dispatch = useDispatch();

  const onSearchTransaction = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== transactionPageData.search) {
      const search = ev.target.value;
      dispatch(setTrsPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== transactionPageData.page) {
      dispatch(setTrsPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetPageData('transactionPageData'));
    };
  }, [dispatch]);

  return (
    <>
      {sendLCModal ? (
        <Wallet
          defaultSection="Send CKU"
          closeModal={setSendLCModal}
          playerEmail={selectedPlayerDetail.email}
        />
      ) : null}
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <h3 className="text-base font-poppins-semibold">
            Transaction History
          </h3>
          {selectedPlayerTrsHistory && selectedPlayerTrsHistory.length ? (
            <button
              type="button"
              onClick={() => setSendLCModal(true)}
              className="ml-[20%] px-[16px] py-[4px] bg-white border border-black-100 rounded-[20px] font-poppins-semibold"
            >
              Send CKU
            </button>
          ) : null}
        </div>
        {selectedPlayerTrsHistory && selectedPlayerTrsHistory.length ? (
          <div>
            <div className="flex flex-col my-[16px]">
              <label className="text-sm ">Search</label>
              <input
                placeholder="Date, Type, Sender/Receiver, Wallet Address"
                onKeyDown={onSearchTransaction}
                className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
              />
            </div>
            <p className="mb-[16px] text-sm">
              <span className="font-poppins-semibold mr-[10%]">
                CKU Wallet Balance
              </span>
              CKU{' '}
              {selectedPlayerDetail.lucky_coin_balance
                ? thousandSeparator(selectedPlayerDetail.lucky_coin_balance)
                : 0}
            </p>
            <DataLimit
              initialLimit={transactionPageData.dataLimit}
              setter={setTrsPageData}
              classList="mb-[16px]"
            />
            <StyledTableContainer>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Transaction Date</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Sender</StyledTableCell>
                    <StyledTableCell>Receiver</StyledTableCell>
                    <StyledTableCell>Sender Wallet Address</StyledTableCell>
                    <StyledTableCell>Receiver Wallet Address</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlayerTrsHistory.map((trs) => (
                    <TableRow key={trs._id}>
                      <StyledTableCell>
                        {moment(trs.createdAt).format('DD-MM-YYYY, HH:mm:ss')}
                      </StyledTableCell>
                      <StyledTableCell>{trs.transaction_type}</StyledTableCell>
                      <StyledTableCell>
                        {setPrizeCurrency(trs)}{' '}
                        {thousandSeparator(trs.total_payment)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {trs.sender_receiver &&
                        trs.sender_receiver.sender &&
                        trs.sender_receiver.sender.name
                          ? trs.sender_receiver.sender.name
                          : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {trs.sender_receiver &&
                        trs.sender_receiver.receiver &&
                        trs.sender_receiver.receiver.name
                          ? trs.sender_receiver.receiver.name
                          : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {trs.sender_receiver &&
                        trs.sender_receiver.sender &&
                        trs.sender_receiver.sender.wallet_address
                          ? trs.sender_receiver.sender.wallet_address
                          : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {trs.sender_receiver &&
                        trs.sender_receiver.receiver &&
                        trs.sender_receiver.receiver.wallet_address
                          ? trs.sender_receiver.receiver.wallet_address
                          : '-'}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            <Pagination
              totalPages={transactionPages}
              pageData={transactionPageData}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          <p className="text-sm">No transaction data found!</p>
        )}
      </div>
    </>
  );
};

export default PlayerTrsHistory;
