import { useEffect, useState } from 'react';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketDetail } from '../actions/ticket';
import Loading from '../components/Common/Loading';
import WinningNumbers from '../components/Season/WinningNumbers';
import moment from 'moment';
import Wallet from '../components/Wallet/Wallet';
import { thousandSeparator } from '../utils/math';
import { usePrizeCurrency } from '../hooks/usePrizeCurrency';

const TicketDetailPage = () => {
  const { selectedTicketInfo, isLoading } = useSelector(
    (state) => state.ticket
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currency = usePrizeCurrency('ticket');
  const [sendLCModal, setSendLCModal] = useState(false);

  useEffect(() => {
    dispatch(fetchTicketDetail({ id }));
  }, [dispatch, id]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {sendLCModal ? (
        <Wallet
          closeModal={setSendLCModal}
          playerEmail={selectedTicketInfo.player_email}
          defaultSection="Send CKU"
        />
      ) : null}
      <LayoutAdmin pageTitle="Tickets" pageSubtitle="Tickets, Ticket Detail">
        {selectedTicketInfo ? (
          <>
            <div className="flex flex-col border-b border-b-grey-20">
              <div className="flex flex-row items-center mb-[21px]">
                <h3 className="text-base font-poppins-semibold w-[50%] lg:w-[20%]">
                  Transaction Detail
                </h3>
                <button
                  type="button"
                  onClick={() => setSendLCModal(true)}
                  className="px-[16px] py-[4px] bg-white border border-black-100 font-poppins-semibold rounded-[20px]"
                >
                  Manual Send CKU
                </button>
              </div>
              <div className="flex flex-row items-center text-sm mb-[8px]">
                <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                  Ticket Number
                </label>
                <p>{selectedTicketInfo.ticket_id}</p>
              </div>
              <div className="flex flex-row items-center text-sm mb-[8px]">
                <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                  Lottery Status
                </label>
                <p>{selectedTicketInfo.ticket_status}</p>
              </div>
              <div className="flex flex-row items-center text-sm mb-[8px]">
                <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                  Lottery Number
                </label>
                <WinningNumbers
                  numbers={selectedTicketInfo.ticket_number}
                  showCopy={false}
                  noPadding={true}
                />
              </div>
              <div className="flex flex-row items-center text-sm mb-[8px]">
                <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                  Purchase Date
                </label>
                <p>
                  {moment(selectedTicketInfo.createdAt).format(
                    'DD-MM-YYYY, HH:mm:ss'
                  )}
                </p>
              </div>
              <div className="flex flex-row items-center text-sm mb-[8px]">
                <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                  Player Email
                </label>
                <p className="underline">{selectedTicketInfo.player_email}</p>
              </div>
              <div className="flex flex-row items-center text-sm pb-[16px]">
                <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                  Manual CKU Sent
                </label>
                <p>-</p>
              </div>
            </div>
            {selectedTicketInfo.ticket_status.includes('Win') ? (
              <section className="mt-[16px]">
                <div className="flex flex-row items-center mb-[21px]">
                  <h3 className="text-base font-poppins-semibold w-[50%] lg:w-[20%]">
                    Prize Detail
                  </h3>
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `/lottery/season/${selectedTicketInfo.lottery_season.id}`
                      )
                    }
                    className="px-[16px] py-[4px] bg-white border border-black-100 font-poppins-semibold rounded-[20px]"
                  >
                    Open Season Detail
                  </button>
                </div>
                <div className="flex flex-row items-center text-sm mb-[8px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                    Winning Position
                  </label>
                  <p>{selectedTicketInfo.prize_ranking}</p>
                </div>
                <div className="flex flex-row items-center text-sm mb-[8px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                    Prize Pot
                  </label>
                  <p>
                    {currency} {thousandSeparator(selectedTicketInfo.prize_pot)}
                  </p>
                </div>
                <div className="flex flex-row items-center text-sm mb-[8px]">
                  <label className="font-poppins-semibold w-[40%] lg:w-[15%]">
                    Prize Won
                  </label>
                  <p>
                    {currency} {thousandSeparator(selectedTicketInfo.prize)}
                  </p>
                </div>
              </section>
            ) : null}
          </>
        ) : null}
      </LayoutAdmin>
    </>
  );
};

export default TicketDetailPage;
