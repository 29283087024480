import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { editSeasonSalesPitch } from '../../actions/season';
import getToastMessage from '../../utils/toastMessage';
import Modal from '../Modal/Modal';
import { useParams } from 'react-router-dom';

const SeasonDeletePitch = ({ closeModal, pitch }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const onDeletePitch = async () => {
    pitch.is_deleted = true;
    pitch.lottery_season_id = id;

    const done = await dispatch(editSeasonSalesPitch(pitch));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };
  return (
    <Modal title="Delete Sales Pitch" closeModal={closeModal}>
      <h3 className="text-base">
        Are you certain about deleting this sales pitch?
      </h3>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal(false)}
          type="button"
          className="w-[45%] px-[16px] py-[8px] text-red border border-black-100 rounded-[20px] font-poppins-semibold"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onDeletePitch}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default SeasonDeletePitch;
