import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePickerInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { editSeason, season } from '../../utils/validationForm';
import clsx from 'clsx';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import '../../assets/styles/customDatePicker.css';
import { createSeason, editSeasonBasicInfo } from '../../actions/season';
import getToastMessage from '../../utils/toastMessage';
const SeasonNewModal = ({ isEdit, closeModal }) => {
  const { control, register, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(isEdit ? editSeason : season),
  });
  const selectedSeasonInfo = useSelector(
    (state) => state.season.selectedSeasonInfo
  );
  const watchIsCashPayout = watch('is_cash_payout');
  const dispatch = useDispatch();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const onSubmit = async (data) => {
    const done = isEdit
      ? await dispatch(editSeasonBasicInfo(data))
      : await dispatch(createSeason(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
        reset();
      }
    }
  };

  useEffect(() => {
    if (isEdit && selectedSeasonInfo) {
      const seasonData = {
        lottery_season_id: selectedSeasonInfo.lottery_season.id,
        name: selectedSeasonInfo.lottery_season.name,
        startDate: new Date(selectedSeasonInfo.startDate),
        ticket_price: selectedSeasonInfo.ticket_price,
      };
      reset(seasonData);
    }
  }, [selectedSeasonInfo, isEdit, reset]);

  return (
    <Modal
      title={isEdit ? 'Edit Season' : 'Create New Season'}
      closeModal={closeModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="name"
          placeholder="Input name"
          title="Season Name"
          register={register}
        />
        <div className="flex flex-col mb-[18px]">
          <label className="text-sm">Start Date</label>
          <div className="customDatePicker">
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePickerInput
                  placeholderText="Select date and time"
                  onChange={(date) => {
                    let formattedDate = new Date(date);
                    if (formattedDate < tomorrow) {
                      const hour = formattedDate.getHours(),
                        minutes = formattedDate.getMinutes();
                      formattedDate = tomorrow;
                      formattedDate.setHours(hour, minutes);
                    }
                    formattedDate.setSeconds(0);
                    field.onChange(formattedDate);
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  selected={field.value ? new Date(field.value) : null}
                  dateFormat="MM/dd/yyyy, HH:mm"
                  showTimeSelect
                  isClearable={isEdit ? false : true}
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  autoComplete="off"
                  disabled={isEdit}
                  minDate={selectedSeasonInfo && isEdit ? new Date() : tomorrow}
                  minTime={new Date().setHours(0, 0)}
                  maxTime={new Date().setHours(23, 59)}
                  className={clsx(
                    `w-full bg-white text-sm block px-[8px] py-[11px] border border-black-100 rounded-lg outline-0 ${
                      isEdit ? 'opacity-50' : ''
                    }`
                  )}
                />
              )}
            />
          </div>
        </div>
        <Input
          name="ticket_price"
          placeholder="CKU"
          title="Ticket Price"
          type="number"
          register={register}
        />
        {!isEdit ? (
          <>
            <div className="flex flex-row justify-between items-center w-full">
              <Input
                name="min_number"
                placeholder="1"
                title="Minimum Number"
                register={register}
                width="45%"
              />
              <Input
                name="max_number"
                placeholder="10"
                title="Max Number"
                register={register}
                width="45%"
              />
            </div>
            <Controller
              control={control}
              name="is_cash_payout"
              render={({ field }) => (
                <div
                  className={`flex flex-row items-center cursor-pointer ${
                    watchIsCashPayout && 'mb-[10px]'
                  }`}
                  onClick={() => field.onChange(!watchIsCashPayout)}
                >
                  <ion-icon
                    name={watchIsCashPayout ? 'checkbox' : 'square-outline'}
                    style={{ width: '20px', height: '20px' }}
                  ></ion-icon>
                  <label className="ml-[5px] text-sm cursor-pointer">
                    Cash Payout Season
                  </label>
                </div>
              )}
            />
          </>
        ) : null}
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => reset()}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            {isEdit ? 'Save' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SeasonNewModal;
