import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const DrawDownList = () => {
  const { drawdownList, selectedDrawdownID } = useSelector(
    (state) => state.drawdown
  );
  const selectedSection = useSelector(
    (state) => state.seasonSection.selectedSection
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const activeDrawdownRef = useRef();
  const drawdownListRef = useRef();

  useEffect(() => {
    if (activeDrawdownRef && activeDrawdownRef.current) {
      const activeOffsetLeft = activeDrawdownRef.current.offsetLeft;
      drawdownListRef.current.scrollTo({
        left: activeOffsetLeft - (window.innerWidth > 640 ? 500 : 100),
        behavior: 'smooth',
      });
    }
  }, [selectedDrawdownID]);

  return (
    <>
      {drawdownList.length ? (
        <div
          className="flex flex-row overflow-auto max-w-none sm:max-w-[80vw] w-full my-[16px]"
          ref={drawdownListRef}
        >
          {drawdownList.map((drawdown) => (
            <button
              key={drawdown.drawdown.id}
              onClick={() => {
                setSearchParams({
                  season_section: selectedSection,
                  draw_id: drawdown.drawdown.id,
                });
              }}
              ref={
                selectedDrawdownID &&
                selectedDrawdownID === drawdown.drawdown.id
                  ? activeDrawdownRef
                  : null
              }
              className={`${
                selectedDrawdownID &&
                selectedDrawdownID === drawdown.drawdown.id
                  ? 'opacity-100'
                  : 'opacity-20'
              } bg-green text-ivory border-r-[2px] py-[12px] px-[16px] font-poppins-semibold box-shadow-green min-w-max`}
            >
              {drawdown.drawdown.name}
            </button>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default DrawDownList;
