import { useState } from 'react';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTable from '../Table/StyledTable';
import StyledTableCell from '../Table/StyledTableCell';
import { TableHead, TableRow, TableBody } from '@mui/material';
import WinnerInfoModal from './WinnerInfoModal';
import Pagination from '../Table/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setWinnerageData } from '../../slices/seasonSlice';
import Loading from '../Common/Loading';
import WinningNumbers from './WinningNumbers';
import { thousandSeparator } from '../../utils/math';
import DrawDownList from './DrawDownList';

const SeasonWinner = () => {
  const [modalSection, setModalSection] = useState('');
  const dispatch = useDispatch();
  const { isLoading, winnerList, winnerPageData, winnerPages, winnerSummary } =
    useSelector((state) => state.season);
  const positionArr = ['1st', '2nd', '3rd'];

  const onSearchWinner = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== winnerPageData.search) {
      const search = ev.target.value;
      dispatch(setWinnerageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== winnerPageData.page) {
      dispatch(setWinnerageData({ page: ev.selected }));
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {modalSection === 'winner_info' ? (
        <WinnerInfoModal closeModal={setModalSection} />
      ) : null}
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <h3 className="text-base font-poppins-semibold">Winner </h3>
          <button
            type="button"
            className="flex ml-[4px]"
            onClick={() => setModalSection('winner_info')}
          >
            <ion-icon
              name="information-circle-outline"
              style={{ width: '16px', height: '16px' }}
            ></ion-icon>
          </button>
        </div>
        <DrawDownList />
        <div className="flex flex-col mb-[24px] mt-[16px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Player ID, Position or Winner Prize"
            onKeyDown={onSearchWinner}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <StyledTableContainer sx={{ width: ['100%', '80%', '80%'] }}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Position</StyledTableCell>
                <StyledTableCell>Player Email</StyledTableCell>
                <StyledTableCell>Ticket ID</StyledTableCell>
                <StyledTableCell>Ticket Numbers</StyledTableCell>
                <StyledTableCell>Winning Pot</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {winnerList && winnerList.length ? (
                <>
                  {winnerList.map((winner, idx) => (
                    <TableRow key={idx}>
                      <StyledTableCell>
                        {positionArr[winner.prize_ranking - 1]}
                      </StyledTableCell>
                      <StyledTableCell>{winner.player_email}</StyledTableCell>
                      <StyledTableCell>{winner.ticket_id}</StyledTableCell>
                      <StyledTableCell>
                        <WinningNumbers
                          showCopy={false}
                          numbers={winner.ticket_number}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {winner.winning_pot
                          ? `CKU ${thousandSeparator(winner.winning_pot)}`
                          : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {winner.prize
                          ? `CKU ${thousandSeparator(winner.prize)}`
                          : '-'}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {positionArr.map((pos, idx) => (
                    <TableRow key={idx}>
                      <StyledTableCell>{pos}</StyledTableCell>
                      <StyledTableCell sx={{ opacity: '0.5' }}>
                        No Winner
                      </StyledTableCell>
                      <StyledTableCell sx={{ opacity: '0.5' }}>
                        No Winner
                      </StyledTableCell>
                      <StyledTableCell sx={{ opacity: '0.5' }}>
                        No Winner
                      </StyledTableCell>
                      <StyledTableCell sx={{ opacity: '0.5' }}>
                        -
                      </StyledTableCell>
                      <StyledTableCell sx={{ opacity: '0.5' }}>
                        -
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        {winnerList && winnerList.length ? (
          <Pagination
            totalPages={winnerPages}
            pageData={{ winnerPageData }}
            onChange={handlePageChange}
          />
        ) : null}
        <div className="flex flex-col mt-[24px]">
          <div className="flex flex-row text-sm">
            <label className="font-poppins-semibold w-[40%] lg:w-[20%]">
              Total Winners
            </label>
            <p>
              {winnerSummary
                ? thousandSeparator(winnerSummary.total_winners)
                : '-'}
            </p>
          </div>
          <div className="flex flex-row text-sm">
            <label className="font-poppins-semibold w-[40%] lg:w-[20%]">
              Total Tickets Purchased
            </label>
            <p>
              {winnerSummary
                ? thousandSeparator(winnerSummary.total_tickets)
                : '-'}
            </p>
          </div>
          <div className="flex flex-row text-sm">
            <label className="font-poppins-semibold w-[40%] lg:w-[20%]">
              Total Players
            </label>
            <p>
              {winnerSummary
                ? thousandSeparator(winnerSummary.total_players)
                : '-'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeasonWinner;
